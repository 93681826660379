<template>
  <div class="select-list" :class="size">
    <div
      class="w-100 selector d-flex align-items-center justify-content-between"
      :class="border"
      @click="selection()"
    >
      <span
        class="fnt-regular fnt-body pe-2"
        id="result"
        v-if="endresult == '' && firtresult == ''"
        :class="device == 'mobile' ? 'fnt-caption' : ''"
        >{{ $t(firstLabel) + ": " + $t(endLabel) }}</span
      >

      <span
        class="fnt-regular fnt-body pe-2"
        id="result"
        v-else
        :class="device == 'mobile' ? 'fnt-caption' : ''"
        >{{ $t(endresult) + ": " + $t(firtresult) }}</span
      >

      <!-- <span>{{ Result }}</span> -->
      <moveDown width="16" height="16" :class="select ? 'rotate' : ''" />
    </div>

    <div
      class="shadow-sm rounded-2"
      :class="device == 'mobile' ? 'lists-select-mobile' : 'lists-select'"
      v-if="select"
    >
      <!-- <template v-for="(item, index) in listSelect" :key="index">
        {{ item.sort[0] }}
      </template> -->

      <template v-for="(items, index) in listSelect" :key="index">
        <template v-for="(item, index) in items.sort" :key="index">
          <div
            class="list d-flex justify-content-between align-items-center"
            v-if="item.name === '89'"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            @click="selected(items.lang, item.name, items.state, item.id)"
          >
            {{ $t(items.lang) }}: <span>{{ $t(item.name) }}</span>
          </div>
          <div
            class="list d-flex justify-content-between align-items-center"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            v-if="item.name === '90'"
            @click="selected(items.lang, item.name, items.state, item.id)"
          >
            {{ $t(items.lang) }}: <span>{{ $t(item.name) }}</span>
          </div>
          <hr v-if="items[item[index]] == 1" class="m-0" />

          <div
            class="list d-flex justify-content-between align-items-center"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            v-if="items.lang === '437'"
            @click="selected(items.lang, item.name, items.state, item.id)"
          >
            {{ $t(items.lang) }}: <span>{{ $t(item.name) }}</span>
          </div>

          <div
            class="list d-flex justify-content-between align-items-center"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            v-if="items.lang === '438'"
            @click="selected(items.lang, item.name, items.state, item.id)"
          >
            {{ $t(items.lang) }}: <span>{{ $t(item.name) }}</span>
          </div>
        </template>
      </template>
    </div>
  </div>
  <div class="overlay" @click="select = false" v-show="select"></div>
</template>

<script>
// import Status from "@/components/status/status.vue";
import { moveDown } from "@/components/icon/index";
export default {
  components: { moveDown },
  emits: ["Filter"],
  data() {
    return {
      select: false,
      // result: "",
      firtresult: "",
      endresult: "",
    };
  },
  methods: {
    selection() {
      this.select = !this.select;
    },
    selected(lang, name, items, item) {
      if (this.result == "") {
        // this.result = items + ": " + item;
        this.firtresult = name;
        this.endresult = lang;
      }
      // this.result = items + ": " + item;
      this.firtresult = name;
      this.endresult = lang;
      this.select = false;
      // console.log(this.firtresult, this.endresult);
      // console.log(items, item);
      this.$emit("Filter", { state: items, id: item });
    },
  },
  props: {
    size: { type: String, default: "" },
    border: { type: String, default: "" },
    listSelect: { type: Object },
    firstLabel: { type: String, default: "19" },
    endLabel: { type: String, default: "89" },
  },
};
</script>

<style lang="scss" scoped>
.select-list {
  position: relative;
  z-index: 99;
  user-select: none;
  .selector {
    padding: 9px 10px;
    width: fit-content;
    border-radius: 4px;
    background-color: $white;
    cursor: pointer;
    transition: 150ms;
    svg {
      transition: 150ms;
    }
    &:hover {
      background-color: $black-50;
    }
    &:active {
      background-color: $black-300;
    }
  }
  .lists-select {
    margin-top: 5px;
    width: 170px;
    background-color: $white;
    transition: 150ms;
    position: absolute;
    top: 41px;
    left: 0;
    .list {
      padding: 4px 16px;
      cursor: pointer;
      &:hover {
        background-color: $black-50;
      }
      &:active {
        background-color: $black-300;
      }
    }
  }
  .lists-select-mobile {
    margin-top: 5px;
    width: 170px;
    background-color: $white;
    transition: 150ms;
    position: absolute;
    top: 31px;
    left: -28px;
    .list {
      padding: 4px 16px;
      cursor: pointer;
      &:hover {
        background-color: $black-50;
      }
      &:active {
        background-color: $black-300;
      }
    }
  }
}
.large {
  padding: 13px 10px !important;
}

.small {
  .selector {
    padding: 4px 10px !important;
  }
  .lists-select {
    top: 31px;
  }
}
.rotate {
  transform: rotate(180deg);
  transition: 150ms;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: 10;
}
</style>