<template>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <div>
    <!-- {{ "blnSelectTemplate:" + blnSelectTemplate }} -->
    <!-- {{ "blnOpenform:" + blnOpenform }} -->
    <!-- {{ 'blnUserGroupType:'+blnUserGroupType }} -->
    <!-- {{ "intUserTemplateID:" + intUserTemplateID }} -->
    <!-- {{ "intMemberID:" + intMemberID }} -->
    <Header
      icon="UserOutline"
      :morebreadcrumbpath="
        blnOpenform && (newaction == 'Update' || newaction == 'Update_special')
          ? true
          : true
      "
      @clickindex="openform(false, $event)"
      :headerName="
        blnUserGroupType
          ? blnOpenform
            ? newaction == 'Insert'
              ? $t('287')
              : $t('406')
            : $t('280')
          : $t('280')
      "
      :DetailheaderName="
        blnUserGroupType == true
          ? intUsergrouptypeID == 1
            ? ' (' + $t('450') + ')'
            : intUsergrouptypeID == 2
            ? ' (' + $t('1101') + ')'
            : ' (' + $t('452') + ')'
          : ''
      "
      :headerNamebreadcrumb="blnUserGroupType == true ? $t('1103') : ''"
      :morebreadcrumbname="
        blnUserGroupType == true
          ? blnOpenform &&
            (newaction == 'Update' || newaction == 'Update_special')
            ? $t('406')
            : $t('287')
          : ''
      "
      :btnName="$t('287')"
      :disabled="false"
      :breadcrumbname="
        blnUserGroupType && !blnSelectTemplate
          ? $t('434')
          : blnUserGroupType && blnSelectTemplate
          ? $t('434')
          : ''
      "
      class="mb-3"
      @onInput="openform($event)"
      :breadcrumb="blnUserGroupType == true ? '' : 'd-none'"
      :helperHeader="
        blnUserGroupType == true
          ? blnOpenform == true
            ? blnSelectTemplate == true
              ? $t('1024')
              : $t('200', { fieldname: $t('6').toLowerCase() })
            : ''
          : $t('1104')
      "
      :rightHeader="
        blnUserGroupType == true
          ? blnOpenform == true
            ? 'd-none'
            : ''
          : 'd-none'
      "
      :hintHelperHeader="''"
      :underline="
        blnUserGroupType == true
          ? blnOpenform == true
            ? 'openUnderline'
            : 'closeUnderline'
          : 'openUnderline'
      "
      ListCampusClass="d-none"
      :filterActive="{
        blnOpenform: blnOpenFilter,
        useFilter: activeFiltersSelected.length == 0 ? false : true,
        disableFilter:
          device == 'mobile'
            ? strFilterlist.length != 0
              ? false
              : true
            : false,
      }"
      :tooltipMsg="strSearchWord != null && strSearchWord != '' ? '101' : '449'"
      :tooltipPlacement="'tooltip-top-mobile'"
      @onSearch="processSearchUserInfo($event)"
      :Searchname="$t('434')"
      @onClean="clean()"
      ref="header"
      @showFilter="blnOpenFilter = !blnOpenFilter"
      :blnOpenform="blnOpenform"
      :filter="device == 'mobile' ? '' : 'd-none'"
      :mode="'enter'"
      searchHeader="fix"
    />
    <!-- เลือกประเภทผู้ใช้ -->
    <!-- {{ intUsergrouptypeID }} -->
    <!-- {{ strUsergrouptype }} -->
    <!-- {{ blnOpenFilter }} -->
    <!-- {{ blnOpenFilter }} -->
    <div
      class="userInfo"
      :style="device == 'mobile' ? 'min-height: 630px;' : 'min-height: 730px;'"
    >
      <div v-if="blnUserGroupType == false">
        <SelectTypeUserInfoVue
          @UsergrouptypeID="onSelectType($event)"
          @UsergrouptypeName="
            (e) => {
              strUsergrouptype = e;
            }
          "
        />
      </div>
      <div v-if="blnUserGroupType == true">
        <!-- ใช้ props ชื่อ heading ใน Header เพื่อเปิด heading ตอนเปิดหน้า More information -->
        <!-- แถบผลการค้นหา & sort -->
        <div
          class="row mb-3 g-0"
          :style="device == 'mobile' ? '' : 'gap: 32px'"
          v-if="blnOpenform == false && blnViewUser == false"
        >
          <div
            class="col-lg-3 col-sm-0"
            v-if="
              progressSearch == 100 &&
              strSearchWord != null &&
              strSearchWord != ''
            "
          ></div>
          <div
            class="col d-flex align-items-center"
            :class="
              strSearchWord == null
                ? 'justify-content-end'
                : 'justify-content-between'
            "
            v-if="
              progressSearch == 100 &&
              strSearchWord != null &&
              strSearchWord != ''
            "
          >
            <div
              v-if="
                progressSearch == 100 &&
                strSearchWord != null &&
                strSearchWord != ''
              "
              class="me-2"
              :class="
                strSearchWord != null ? '' : 'opacity-0 visibility-hidden'
              "
            >
              <span
                class="fnt-h6 fnt-light"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
              >
                {{ $t("312") }}
              </span>
              <span
                class="fnt-h6 fnt-medium"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
                >{{ listUserInfoClone.length }}
              </span>
              <span
                class="fnt-h6 fnt-light"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
              >
                {{ $t("433") }}
              </span>

              <span
                class="fnt-h6 fnt-medium"
                :class="device == 'mobile' ? 'fnt-caption ' : ''"
                >{{
                  strSearchWord != "" ? strSearchWord : strShowsearchWord
                }}</span
              >
            </div>
            <div
              class="d-flex align-items-center"
              v-if="listUserInfo.length != 0"
            >
              <span
                class="me-1 fnt-medium"
                :class="device == 'mobile' ? 'd-none' : ''"
                >{{ $t("313") }}</span
              >
              <Select
                size="small"
                style="width: 200px !important"
                border="border"
                :listSelect="listSelect"
                :firstLabel="firstLabel"
                :endLabel="endLabel"
                @Filter="SortBy($event)"
              />
            </div>
          </div>
        </div>
        <!-- show Card -->
        <div
          class="row g-0"
          style="gap: 32px"
          v-if="listUserInfo.length != 0 && blnOpenform == false"
        >
          <!-- <div
      class="col-3 bg-white rounded-3 shadow-sm p-5 left"
      v-if="device != 'mobile'"
      v-if="
      listUserInfo.length != 0 && blnOpenform == false && blnNotSearch == true
    "
    >
      <Left @Close="ClearFillter()" :filterlist="strFilterlist" />
    </div> -->
          <!-- filter mobile -->
          <div
            class="filter-mobile bg-white rounded-3 shadow-sm p-5 left"
            v-if="
              device == 'mobile' &&
              strFilterlist.length != 0 &&
              listUserInfo?.length > 1
            "
            :class="blnOpenFilter == true ? 'show-filter-mobile' : ''"
          >
            <Filter
              :listKeyword="strFilterlist"
              @selectfilter="
                selectfilter($event.listFilterKeyword, $event.filter)
              "
            />
            <div></div>
          </div>
          <!-- filter PC -->
          <div
            class="col-3 left"
            v-if="
              device != 'mobile' &&
              listUserInfo.length > 1 &&
              blnsorting == true
            "
          >
            <!-- skeleton รอ sorting เสร็จ -->
            <Loading1 v-if="progressSearch != 100" class="h-100" />
          </div>
          <!-- {{ listUserInfo.length }} -->
          <!-- {{ listUserInfo.length }}{{ strFilterlist[0].listFilterGroupData }} -->
          <div
            v-if="
              device != 'mobile' &&
              listUserInfo.length != 0 &&
              strFilterlist.length != 0 &&
              blnsorting == false
            "
            class="col-3 p-5 left 123"
            :class="
              // strFilterlist.length > 1
              //   ? 'bg-white rounded-3 shadow-sm'
              //   : 'border-end'
              strFilterlist[0]?.listFilterGroupData?.length == 1 ||
              strFilterlist[0]?.listFilterGroupData?.length == 0 ||
              strFilterlist[0]?.listFilterGroupData == null ||
              listUserInfo?.length == 1
                ? 'border-end'
                : 'bg-white rounded-3 shadow-sm'
            "
          >
            <!-- <pre>{{ strFilterlist[0]?.listFilterGroupData?.length }}</pre> -->
            <!-- <Filter
              v-if="strFilterlist[0]?.listFilterGroupData?.length == 1"
              :listKeyword="strFilterlist"
              @selectfilter="
                selectfilter($event.listFilterKeyword, $event.filter)
              "
            /> -->
            <Filter
              v-if="
                strFilterlist[0]?.listFilterGroupData?.length > 1 &&
                listUserInfo.length > 1
              "
              :listKeyword="strFilterlist"
              @selectfilter="
                selectfilter($event.listFilterKeyword, $event.filter)
              "
            />
            <!-- ไม่มีข้อมูลที่ถูกจัดกลุ่ม -->
            <div
              class="d-flex flex-column align-items-center mt-9 pt-4"
              v-if="
                strFilterlist[0]?.listFilterGroupData?.length == 1 ||
                strFilterlist[0]?.listFilterGroupData?.length == 0 ||
                strFilterlist[0]?.listFilterGroupData == null ||
                listUserInfo?.length == 1
              "
            >
              <FilterFill width="40" height="40" class="fill-black-500" />
              <span class="fnt-regular fnt-subtitle text-nowrap">{{
                $t("101")
              }}</span>
            </div>
          </div>

          <div
            v-if="
              device != 'mobile' &&
              blnOpenFilter == true &&
              strFilterlist.length == 0
            "
            class="col-3 p-5"
            style="border-right: 1px solid #e9e9e9"
          >
            <Loading1 v-if="progressSearch != 100" class="h-100" />
            <div class="d-flex flex-column align-items-center mt-9 pt-4">
              <FilterFill width="40" height="40" class="fill-black-500" />
              <span class="fnt-regular fnt-subtitle text-nowrap">{{
                $t("101")
              }}</span>
            </div>
          </div>

          <div
            class="col rounded-3 right"
            v-if="blnOpenform == false && listUserInfo.length != 0"
            @scroll="handleScroll($event)"
          >
            <!-- skeleto card mobile -->
            <Loading1 v-if="device == 'mobile' && blnsorting == true" />
            <Cards
              :listuserinfoData="listUserInfoCrop"
              :blnShowIndex="true"
              :strAction="action"
              @edituserinfo="editUserInfo($event)"
              @previewuserinfo="previewuserinfo($event)"
            />
          </div>
        </div>

        <div
          class="row g-0"
          v-if="blnOpenform == false && blnViewUser !== true"
          style="gap: 32px"
        >
          <div class="col-3"></div>
          <div class="col d-flex justify-content-start">
            <!-- <Pagination
              v-if="listUserInfo.length != 0"
              :total="listUserInfo.length"
              :datatotal="listUserInfo.length"
              :total-pages="currentPage"
              :perPage="listUserInfo.length"
              :currentPage="page"
              :blnPerPage="false"
              @pagechanged="onPageChange($event)"
              @perpage="perpageChnage($event)"
              ref="Pagination"
            /> -->
          </div>
        </div>
        <!-- ถ้ายังไม่ค้นหาจะให้ blnNotSearch เป็น false -->

        <!-- รอผลการค้นหา -->
        <div
          class="row g-0"
          style="gap: 32px; height: fit-content !important"
          v-if="listUserInfo.length == 0 && blnOpenform == false"
        >
          <div
            v-if="
              device != 'mobile' &&
              blnOpenFilter == true &&
              strFilterlist.length != 0
            "
            class="col-3 bg-white rounded-3 shadow-sm p-5 left"
          >
            <Filter
              @selectfilter="
                selectfilter($event.listFilterKeyword, $event.filter)
              "
            />
          </div>
          <div
            class="col-3 border-end"
            :class="progressSearch == 100 ? 'pt-10' : ''"
            v-if="
              device != 'mobile' &&
              blnOpenFilter == true &&
              strFilterlist.length == 0
            "
          >
            <Loading1 v-if="progressSearch != 100" class="h-100" />
            <div
              class="d-flex flex-column align-items-center"
              v-if="progressSearch == 100"
            >
              <FilterFill width="40" height="40" class="fill-black-500" />
              <span class="fnt-regular fnt-subtitle text-nowrap">{{
                $t("101")
              }}</span>
            </div>
          </div>

          <!-- ก่อนค้นหา -->
          <div
            v-if="device != 'mobile' && blnOpenFilter == false"
            class="col-3 p-5"
            :class="progressSearch == 100 ? '' : 'px-5 py-0'"
            style="border-right: 1px solid #e9e9e9"
          >
            <Loading1
              v-if="
                progressSearch != 100 &&
                strSearchWord != null &&
                strSearchWord != ''
              "
              class="h-100"
            />
            <!-- รอผลการค้นหา -->
            <div
              class="d-flex flex-column align-items-center mt-9 pt-4"
              v-if="strSearchWord == null || strSearchWord == ''"
            >
              <FilterFill width="40" height="40" class="fill-black-500" />
              <span class="fnt-regular fnt-subtitle">{{ $t("449") }}</span>
            </div>
            <!-- ไม่มีข้อมูลที่ถูกจัดกลุ่ม -->
            <div
              class="d-flex flex-column align-items-center mt-9 pt-4"
              v-if="
                strSearchWord != null &&
                strSearchWord != '' &&
                progressSearch == 100
              "
            >
              <FilterFill width="40" height="40" class="fill-black-500" />
              <span class="fnt-regular fnt-subtitle text-nowrap">{{
                $t("101")
              }}</span>
            </div>
          </div>
          <!-- ยังไม่ค้น -->
          <div
            class="col rounded-3 right"
            :class="
              strSearchWord != null &&
              strSearchWord != '' &&
              listUserInfo.length != 0
                ? 'shadow-sm'
                : ''
            "
          >
            <div
              class="d-flex flex-column align-items-center justify-content-center py-7"
              v-if="strSearchWord == null || strSearchWord == ''"
            >
              <Icon
                icon="bxs:user-circle"
                :width="device == 'mobile' ? '80' : '100'"
                :height="device == 'mobile' ? '80' : '100'"
                class="mb-2 text-black-500"
              />
              <span
                class="fnt-regular fnt-h5"
                :class="device == 'mobile' ? 'fnt-title' : ''"
                >{{ $t("316") }}</span
              >
            </div>
            <Loading1
              v-if="
                progressSearch != 100 &&
                strSearchWord != null &&
                strSearchWord != ''
              "
              class="h-100 shadow"
            />
            <NotFound
              :detail="'454'"
              classNotFound="fnt-regular fnt-h5"
              v-if="
                progressSearch == 100 &&
                listUserInfo.length == 0 &&
                strSearchWord != null &&
                strSearchWord != ''
              "
              class="mt-5"
            />
          </div>
        </div>
        <div
          class="bg-white shadow-sm position-relative"
          :class="
            device == 'mobile' ? 'p-3 pt-4 fnt-caption' : 'form-lg p-5 pt-6'
          "
          v-if="blnSelectTemplate == true && action == 'Insert'"
        >
          <div class="mb-3">
            <span class="fnt-medium">{{
              $i18n.locale == "th"
                ? "เทมเพลตผู้ใช้ล่าสุด"
                : "Latest User Templates"
            }}</span>
          </div>

          <div
            v-for="(template, index) in listUserTemplate"
            :key="index"
            @click="selectedTemplate(template.strID)"
            class="card p-3 selected-template"
            :class="listUserTemplate?.length == index ? '' : 'mb-3'"
          >
            <div
              class=""
              :class="
                device == 'mobile' ? '' : 'd-flex justify-content-between'
              "
            >
              <Button
                class="p-0"
                :className="device == 'mobile' ? 'fnt-caption fnt-medium' : 'fnt-medium'"
                :name="template.strUserTemplateName"
                >{{ template.strUserTemplateName }}</Button
              >
              <!-- <p class="mb-2 text-white">{{ template.strDateTime }}</p> -->
            </div>
            <div class="d-flex">
              <div>
                <TemplateOutline width="12" height="16" viewBox="0 0 12 16" />
              </div>
              <div>
                <p class="ps-1 m-0">
                  {{
                    $i18n.locale == "th"
                      ? "เทมเพลตเริ่มต้น"
                      : "Default template"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- form -->
        <div
          class="d-flex mt-sm-3 ms-md-0 m-sm-0 justify-content-start align-items-start"
          v-if="blnOpenform == true && blnSelectTemplate == false"
          :class="
            device == 'mobile' || device == 'tablet'
              ? 'flex-wrap move-to-top-level'
              : ''
          "
        >
          <div
            class="rounded-2 me-sm-0 layout"
            :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'w-100'"
          >
            <!-- FormOnEdit -->
            <FormUser
              class="w-100"
              :intMemberID="intMemberID"
              :intUserTypeID="intUserTypeID"
              :listUserInfo="listUserInfoOnEdit"
              :Action="action"
              :intUserTemplateID="
                intUserTemplateID != '' ? intUserTemplateID : 1
              "
              :intUsergrouptypeID="intUsergrouptypeID"
              @closeForm="openform($event, 2)"
              :newaction="newaction"
              @keywordFromUpdate="
                (keyword) => {
                  this.strSearchWord = keyword;
                }
              "
              @EditSucess="updatecomplete($event)"
              @gotoUpdate="gotoUpdate($event)"
              @action="
                (e) => {
                  action = e;
                }
              "
            />
            <!-- <FormUser
          @onInput="openform($event)"
          @showForm="openform($event)"
          @insertcomplete="insertcomplete($event)"
          @keywordFromInsert="keywordFromInsert($event)"
          @menberIDFromInsert="searchAfterInsert($event)"
          v-if="blnOpenform == true && blnShowUserInfoEdit == false"
        /> -->

            <!-- จะแสดงเมื่อกด More information -->
            <!-- <Viewuser
          :listUserInfo="listUserInfoEdit[0].listUserInfo"
          :blnView="false"
          v-if="blnViewUser == true && blnShowUserInfoEdit == true"
          :intMemberID="intMemberID"
          :intTemplateID="intTemplateID"
          @updatecomplete="updatecomplete($event)"
          @cancel="closeViewuser()"
          :usertype="strUserTypeName"
        /> -->
          </div>
        </div>
      </div>
      <!-- Overlay mobile -->
      <div
        v-if="blnOpenFilter == true && device == 'mobile'"
        class="overlay-filter-mobile"
        :class="blnOpenFilter == true ? 'show-overlay-filter-mobile' : ''"
        @click="blnOpenFilter = false"
      ></div>
    </div>
  </div>
</template>

<script>
import { FilterFill } from "@/components/icon/index";
import Cards from "@/components/cards/userFix.vue";
import Button from "@/components/button/button.vue";
import { Icon } from "@iconify/vue";
// import FormUser from "@/components/form/UserInfo/userInfo.vue";
import FormUser from "@/views/systemadmin/userinfo/FormOnEdit.vue";
// import Pagination from "@/components/pagination/pagination.vue";
import Select from "@/components/input/select.vue";
import Filter from "@/views/systemadmin/userinfo/filterFirst.vue";
import AdsInventories from "@/typescript/inventory";
// import SelectDropdown from "@/components/input/dropdown.vue";
// import Right from "@/views/systemadmin/userinfo/result.vue";
// import Left from "@/views/systemadmin/userinfo/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import { TemplateOutline } from "@/components/icon/index";
import axios from "axios";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import urlapi from "@/typescript/urlapi/url";
import NotFound from "@/components/notFound/notFound.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import Enumerable from "linq";
import Loading1 from "@/components/Skeleton.vue";
import SelectTypeUserInfoVue from "@/views/systemadmin/userinfo/SelectTypeUserInfo.vue";

export default {
  components: {
    Header,
    // Left,
    // Right,
    Button,
    Select,
    TemplateOutline,
    FormUser,
    // SelectDropdown,
    // Pagination,
    Icon,
    //Viewuser,
    Cards,
    Filter,
    FilterFill,
    NotFound,
    AltStatus,
    Loading1,
    SelectTypeUserInfoVue,
  },
  data() {
    return {
      listUserTemplate: [
        {
          strID: 1,
          strUserTemplateName: "Thai student template default",
          strDateTime: "1 มี.ค. 2566",
        },
        {
          strID: 2,
          strUserTemplateName: "Foreign student template default",
          strDateTime: "26 ธ.ค. 2565",
        },
      ],
      strSMSGsuccess: "",
      blnSelectTemplate: false,
      hover: false, //hover alt
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      strUserTypeName: null,
      currentData: [],
      perpage: 20,
      page: null,
      usercolor: "",
      listUserInfoEdit: [],
      emits: ["User ID", "Firstname, Lastname"],
      patron: ["Student", "Staff"],
      selectdatatype: "Text",
      // typeSchema: typeSchema,
      hotsearch: false,
      formdata: {},
      listActiveFilters: [],
      blnCheckSelection: [],
      listUserInfo: [],
      listUserInfoOnEdit: [],
      listUserInfoClone: [],
      SelectedValue: [],
      blnShowUserInfoEdit: false,
      activeFiltersSelected: [],
      blnOpenform: false,
      blnOpenFilter: false,
      ListDummy: {},
      blnNotSearch: false,
      blnViewUser: false,
      intMemberID: null,
      strSearchWord: null, //คำที่ใช้ค้นหา
      strShowsearchWord: null, //คำที่ใช้แสดงเมื่อคำที่ค้นเป็น ''
      intTemplateID: null,
      intmenberIDFromInsert: null,
      strFilterlist: [],
      opened: [],
      listDataSelection: [
        { id: 3, lang: "61" }, //open
        { id: 2, lang: "170" }, //In process
        { id: 1, lang: "171" }, //Suspend
        { id: 0, lang: "62" }, //close
      ],
      listSelect: [],

      listKeyword: {
        SearchKeyword: "",
        Status: "All",
        UserType: "Student",
        Academics: {},
        Department: {},
      },
      updateAdsInventories: null,
      listDummyUserinfo: [],
      listUserdata: [],
      listSorting: { state: "Staus", id: 1 },
      progressSearch: 0,
      blnUserGroupType: false,
      intUsergrouptypeID: 0,
      strUsergrouptype: "",
      action: "Insert",
      newaction: "Insert",
      intUserTypeID: "",
      intUserTemplateID: "",
      firstLabel: "",
      endLabel: "",
      maxcount: 20,
      mincount: 0,
      listUserfieldIDcard: [
        {
          intUserfieldID: [
            1,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            19,
            20,
            23,
            39,
            40,
            41,
            145,
            147,
            148,
            149,
            150,
            151,
            152,
            153,
            154,
            161,
            163,
            205,
            206,
            207,
            208,
            209,
            210,
            211,
            212,
            213,
            214,
            215,
            216,
            218,
            219,
            221,
            222,
            226,
            228, //พนักงาน
          ],
        },
      ],
      blnsorting: false,
      listCheckmember: [],
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    localStorage.setItem("userinfoInsert", false);
    this.updateAdsInventories = setInterval(() => {
      if (AdsInventories.blnCloseform == false) {
        localStorage.setItem("userinfoInsert", false);
        AdsInventories.blnCloseform = true;
        this.blnUserGroupType = false;
        this.blnOpenform = false;
        this.blnOpenFilter = false;
        // this.openform(false);
        // this.ClearFillter();
        this.clean();
      }
    }, 500);
  },
  methods: {
    // sorting
    async SortBy(event) {
      // console.log(event);
      this.listSorting = event;
      // this.listUserInfo = await this.listDummyUserinfo.slice();
      if (event.state == "Staus") {
        this.listUserInfo = await this.SingleArraySort(
          this.listUserInfo,
          this.filterUserFieldInUserinfo(
            228,
            this.listUserInfo,
            event.state,
            228,
            229
          ),
          event.state
        );
        setTimeout(() => {
          // console.log(event.id);
          if (event.id == 2) {
            this.listUserInfo.reverse();
          }
          this.listUserInfoClone = this.listUserInfo;
        }, 1000);
      }
      if (event.state == "UserID") {
        this.listUserInfo = await this.SingleArraySort(
          this.listUserInfo,
          this.filterUserFieldInUserinfo(
            1,
            this.listUserInfo,
            event.state,
            1,
            1
          ),
          event.state
        );
        setTimeout(() => {
          // console.log(event.id);
          if (event.id == 2) {
            this.listUserInfo.reverse();
          }
          this.listUserInfoClone = this.listUserInfo;
        }, 1000);
      }
      if (event.state == "FullnameTH") {
        this.listUserInfo = await this.SingleArraySort(
          this.listUserInfo,
          this.filterUserFieldInUserinfo(
            4,
            this.listUserInfo,
            event.state,
            148,
            207
          ),
          event.state
        );
        setTimeout(() => {
          // console.log(event.id);
          if (event.id == 1) {
            this.listUserInfo.reverse();
          }
          this.listUserInfoClone = this.listUserInfo;
        }, 1000);
      }
      if (event.state == "FullnameEN") {
        this.listUserInfo = await this.SingleArraySort(
          this.listUserInfo,
          this.filterUserFieldInUserinfo(
            8,
            this.listUserInfo,
            event.state,
            152,
            211
          ),
          event.state
        );
        setTimeout(() => {
          // console.log(event.id);
          if (event.id == 1) {
            this.listUserInfo.reverse();
          }
          this.listUserInfoClone = this.listUserInfo;
        }, 1000);
      }
    },
    // sort
    SingleArraySort(array, key1, state) {
      // console.log(key1);
      if (state == "Staus") {
        // array = array.sort((a, b) => {
        //   const o1 =
        //     typeof a.listUserfield[this.filterUserfiled(228, a).index] ==
        //     "undefined"
        //       ? {
        //           strNameEN: "Active student",
        //           strNameTH: "นักศึกษาปัจจุบัน",
        //           strActiveFlag: "0",
        //         }
        //       : JSON.parse(
        //           a.listUserfield[this.filterUserfiled(228, a).index]
        //             .strUserInfoData
        //         );

        //   const o2 =
        //     typeof b.listUserfield[this.filterUserfiled(228, a).index] ==
        //     "undefined"
        //       ? {
        //           strNameEN: "Active student",
        //           strNameTH: "นักศึกษาปัจจุบัน",
        //           strActiveFlag: "0",
        //         }
        //       : JSON.parse(
        //           a.listUserfield[this.filterUserfiled(228, a).index]
        //             .strUserInfoData
        //         );
        //         console.log(o1)
        //         console.log(o2)
        //   console.log(
        //     o1.strActiveFlag.toString().toLowerCase(),
        //     o2.strActiveFlag.toString().toLowerCase()
        //   );
        //   if (
        //     o1.strActiveFlag.toString().toLowerCase() >
        //     o2.strActiveFlag.toString().toLowerCase()
        //   )
        //     return -1;
        //   if (
        //     o1.strActiveFlag.toString().toLowerCase() <
        //     o2.strActiveFlag.toString().toLowerCase()
        //   )
        //     return 1;

        //   return 0;
        // });
        array = array.sort((a, b) => {
          const o1 =
            typeof a.listUserFieldData[
              this.filterUserfiled(228, a, 228, 229).index
            ] == "undefined"
              ? 0
              : // {
                //     strNameEN: "Active student",
                //     strNameTH: "นักศึกษาปัจจุบัน",
                //     strActiveFlag: "0",
                //   }
                JSON.parse(
                  a.listUserFieldData[
                    this.filterUserfiled(228, a, 228, 229).index
                  ].strData
                );

          const o2 =
            typeof b.listUserFieldData[
              this.filterUserfiled(228, b, 228, 229).index
            ] == "undefined"
              ? 0
              : // {
                //     strNameEN: "Active student",
                //     strNameTH: "นักศึกษาปัจจุบัน",
                //     strActiveFlag: "0",
                //   }
                JSON.parse(
                  b.listUserFieldData[
                    this.filterUserfiled(228, b, 228, 229).index
                  ].strData
                );
          // console.log(o1, o2);
          if (o1 > o2) return -1;
          if (o1 < o2) return 1;

          return 0;
        });
      } else if (state == "UserID") {
        array = array.sort((a, b) => {
          const o1 =
            typeof a.listUserFieldData[
              this.filterUserfiled(1, a, 145, 205).index
            ] == "undefined"
              ? 0
              : a.listUserFieldData[
                  this.filterUserfiled(1, a, 145, 205).index
                ].strData
                  .toString()
                  .toLowerCase();

          const o2 =
            typeof b.listUserFieldData[
              this.filterUserfiled(1, b, 145, 205).index
            ] == "undefined"
              ? 0
              : b.listUserFieldData[
                  this.filterUserfiled(1, b, 145, 205).index
                ].strData
                  .toString()
                  .toLowerCase();

          // console.log(o1, o2);
          if (parseInt(o1) > parseInt(o2)) return -1;
          if (parseInt(o1) < parseInt(o2)) return 1;

          return 0;
        });
        // console.log(array);
      } else if (state === "FullnameTH") {
        if (
          key1.userfieldID == 4 ||
          key1.userfieldID == 148 ||
          key1.userfieldID == 207
        ) {
          // console.log(key1.userfieldID);
          array = array.sort((a, b) => {
            const o1 =
              a.listUserFieldData[
                this.filterUserfiled(4, a, 148, 207).index
              ].strData
                .toString()
                .toLowerCase() +
              a.listUserFieldData[
                this.filterUserfiled(6, a, 150, 209).index
              ].strData
                .toString()
                .toLowerCase();
            const o2 =
              b.listUserFieldData[
                this.filterUserfiled(4, b, 148, 207).index
              ].strData
                .toString()
                .toLowerCase() +
              b.listUserFieldData[
                this.filterUserfiled(6, b, 150, 209).index
              ].strData
                .toString()
                .toLowerCase();

            // console.log(o1);
            // console.log(o2);
            if (o1 > o2) return -1;
            if (o1 < o2) return 1;

            return 0;
          });
        }
      } else if (state === "FullnameEN") {
        if (
          key1.userfieldID == 8 ||
          key1.userfieldID == 152 ||
          key1.userfieldID == 211
        ) {
          // console.log(key1.userfieldID);
          array = array.sort((a, b) => {
            const o1 =
              a.listUserFieldData[
                this.filterUserfiled(8, a, 152, 211).index
              ].strData
                .toString()
                .toLowerCase() +
              a.listUserFieldData[
                this.filterUserfiled(10, a, 154, 213).index
              ].strData
                .toString()
                .toLowerCase();
            const o2 =
              b.listUserFieldData[
                this.filterUserfiled(8, b, 152, 211).index
              ].strData
                .toString()
                .toLowerCase() +
              b.listUserFieldData[
                this.filterUserfiled(10, b, 154, 213).index
              ].strData
                .toString()
                .toLowerCase();

            // console.log(o1);
            // console.log(o2);
            if (o1 > o2) return -1;
            if (o1 < o2) return 1;

            return 0;
          });
        }
      }

      return array;
    },
    // ฟิวเตอร์
    filterUserfiled(key, array, key2, key3) {
      let keyindex = {};
      array.listUserFieldData.filter((item, index) => {
        if (
          item.userfieldID == key ||
          item.userfieldID == key2 ||
          item.userfieldID == key3
        ) {
          keyindex = { index: index, userfieldID: item.userfieldID };
        }
      });
      return keyindex;
    },
    // ฟิวเตอร์ userfile ใน userinfo
    filterUserFieldInUserinfo(key, array, state, key2, key3) {
      let keyindex = {};
      // console.log(array);
      if (state !== "Fullname" && state != "Lastname") {
        Object.values(array).forEach((ele) => {
          ele.listUserFieldData.filter((item, index) => {
            if (
              item.userfieldID == key ||
              item.userfieldID == key2 ||
              item.userfieldID == key3
            ) {
              keyindex = { index: index, userfieldID: item.userfieldID };
            }
          });
        });
      } else if (state == "Fullname") {
        Object.values(array).forEach((ele) => {
          ele.listUserFieldData.filter((item, index) => {
            if (item.intHeadingLevel === "1") {
              if (item.intUserFieldID == 103 || item.intUserFieldID == 105) {
                keyindex = { index: index, userfieldID: item.intUserFieldID };
              }
            }
          });
        });
      } else if (state == "Lastname") {
        Object.values(array).forEach((ele) => {
          ele.listUserFieldData.filter((item, index) => {
            if (item.intHeadingLevel === "1") {
              if (item.intUserFieldID == 104 || item.intUserFieldID == 109) {
                keyindex = { index: index, userfieldID: item.intUserFieldID };
              }
            }
          });
        });
      }
      // console.log(keyindex);
      return keyindex;
    },

    // อัพเดทข้อมูลสำเร็จ
    updatecomplete(event) {
      // console.log("updatecomplete");
      if (event == true) {
        // console.log(event);
        this.blnShowUserInfoEdit = false;
        this.blnNotSearch = false;
        this.blnViewUser = false;
        this.blnOpenform = false;

        this.listUserInfo = [];
        if (this.$refs.header != undefined) {
          this.$refs.header.$refs.inputSearch.strSearch = this.strSearchWord;
        }
        this.processSearchUserInfo(this.strSearchWord);
        //MSG
        if (this.newaction == "Insert") {
          this.strSMSGsuccess = "26";
        } else {
          this.strSMSGsuccess = "27";
        }

        this.blnAltStatus = true;
        setTimeout(() => {
          this.blnAltStatus = false;
        }, 1000);
      }
    },
    // ตรวจสอบ usertype
    checkUsertype() {
      if (this.user[0].usertype == "Officer") {
        this.usercolor = "officer";
        // console.log(this.usercolor);
      }
    },
    // เปิด/ปิด ฟอร์ม
    openform(state, index) {
      this.action = "Insert";
      // console.log(this.action);
      this.blnOpenform = state;
      this.listUserInfoOnEdit = [];
      this.listUserInfo = [];
      this.intMemberID = null;
      //this.blnNotSearch = false;

      if (state == false) {
        localStorage.setItem("userinfoInsert", false);
        this.blnViewUser = false;
        this.blnShowUserInfoEdit = false;
        this.blnSelectTemplate = false;
        this.formdata = {};

        if (this.blnOpenform == false && index == 1) {
          // กลับไปหน้าเลือกกลุ่มผู้ใช้
          this.blnUserGroupType = false;
          this.blnOpenform = false;
          this.blnOpenFilter = false;
          this.clean();
        } else if (this.blnOpenform == true && index == 2) {
          // หน้าค้นหา
          this.blnUserGroupType = true;
          this.blnOpenFilter = false;
          this.blnOpenform = false;
          this.processSearchUserInfo(this.strSearchWord);
        }

        setTimeout(() => {
          // loading search form
          if (this.blnUserGroupType == true && this.blnOpenform == false) {
            this.processSearchUserInfo(this.strSearchWord);
          } else if (
            this.blnUserGroupType == false &&
            this.blnOpenform == false
          ) {
            this.clean();
          }
          // console.log(this.blnUserGroupType);
          // console.log(this.blnOpenFilter);
          // console.log(this.blnOpenform);
        }, 100);

        this.SortBy({ state: "Staus", id: 1 });
      } else if (state == true) {
        // console.log(state);
        this.newaction = "Insert";
        this.blnSelectTemplate = true;
        this.blnOpenform = true;
        this.blnOpenFilter = false;
        this.intUserTypeID = "";

        // clear search
        this.strSearchWord = null;
        this.intSearchResult = 0;
        if (this.$refs.header != null) {
          this.$refs.header.onClear();
        }
        this.listUserInfo = [];
      }
    },
    // เลือกฟิวเตอร์
    async selectfilter(filter) {
      // console.log(filter);

      if (filter.length > 0) {
        let userfieldID = Enumerable.from(filter)
          .select((s) => s.userfieldID)
          .toArray();
        let strID = Enumerable.from(filter)
          .select((s) => s.strID.toString())
          .toArray();

        // console.log(userfieldID, strID);
        /// filter from userinfo list

        this.listUserInfoClone = Enumerable.from(this.listUserInfo)
          .where((obj) => {
            return obj.listUserFieldData.some((group) => {
              if (userfieldID.includes(group.userfieldID)) {
                if (group.strData !== null) {
                  try {
                    if (group.strData != "") {
                      let strData = JSON.parse(group.strData);
                      // console.log(strData);
                      if (strData?.strID?.includes(",")) {
                        let strIDArray = strData.strID.split(",");
                        return strIDArray.some((id) => strID.includes(id));
                      } else if (
                        obj.intUserTypeID === 1 ||
                        obj.intUserTypeID === 2
                      ) {
                        return strID
                          .toString()
                          .split(",")
                          .includes(strData.strID?.toString());
                      } else if (
                        obj.intUserTypeID === 3 ||
                        obj.intUserTypeID === 4
                      ) {
                        return (
                          strID
                            .toString()
                            .split(",")
                            .includes(strData.strParentID?.toString()) ||
                          strID
                            .toString()
                            .split(",")
                            .includes(strData.strID?.toString())
                        );
                      }
                    }
                  } catch (error) {
                    console.error(error);
                    // Handle the error, such as setting a default value or skipping the item
                    return false;
                  }
                }
              }
            });
          })
          .toArray();
      } else {
        this.listUserInfoClone = this.listDummyUserinfo;
      }
    },
    // แก้ไขข้อมูล userinfo จากการ์ด
    async editUserInfo(event) {
      // console.log("editUserInfo");
      // this.action = "Update";
      this.action = "Update_special";
      this.intMemberID = event.intMemberID;
      this.intUserTypeID = event.typeID;
      this.intUserTemplateID = event.intUserTemplateID;
      this.newaction = "Update";
      this.blnOpenform = true;
      this.blnOpenFilter = false;
      this.listUserInfoOnEdit = this.listUserInfo;
      // this.blnNotSearch = false;
      // this.listUserInfo.forEach((element) => {
      //   if (element.intMemberID == this.intMemberID) {
      //     console.log(element);
      //     this.listUserInfoOnEdit = [];
      //     this.listUserInfoOnEdit.push(element);
      //     console.log(this.listUserInfoOnEdit);
      //   }
      // });
    },
    // ดึงข้อมูลผู้ใช้โดยใช้ memberID
    async getUserInfoByID() {
      try {
        var res = await axios.get(
          urlapi +
            apiuserinfo.GetUserInfoByID +
            AdsInventories.userinfo.intMemberID +
            "?UserInfoID=" +
            this.intMemberID
        );
        // console.log(
        //   urlapi +
        //     apiuserinfo.GetUserInfoByID +
        //     AdsInventories.userinfo.intMemberID +
        //     "?UserInfoID=" +
        //     this.intMemberID
        // );
        // console.log(res.data.data);
        this.listUserInfoEdit = await res.data.data.slice();
        // let dummy = await res.data.data.slice();

        await this.listUserInfoEdit[0].listUserInfo.forEach((child) => {
          // console.log(JSON.stringify(dummy[0]));
          child.listUserField.forEach((ele, index) => {
            if (ele.intFieldSetID != 0) {
              // console.log(JSON.stringify(dummy[0]));
              let data = {
                strID: ele.intFieldSetID,
                strUserFieldNameEN: ele.strUserFieldSetNameEN,
                strUserFieldNameTH: ele.strUserFieldSetNameEN,
                intUserInfoOrder: ele.intUserInfoOrder,

                intFieldSetID: 0,
                intFieldType: 1,
                items: [],
              };
              if (this.listUserInfoEdit[0].listUserInfo[index] != undefined) {
                this.listUserInfoEdit[0].listUserInfo[
                  index
                ].listUserField.forEach((field) => {
                  if (field.intFieldSetID == ele.intFieldSetID) {
                    data.items.push(field);

                    this.listUserInfoEdit[0].listUserInfo[index].listUserField =
                      this.listUserInfoEdit[0].listUserInfo[
                        index
                      ].listUserField.filter((childindex) => {
                        return (
                          field.intUserFieldID != childindex.intUserFieldID
                        );
                      });
                  }
                  // else {
                  //   field.push({ items: [] });
                  // }
                });
              }

              data.items = Enumerable.from(data.items)
                .orderByDescending((r) => r.intFieldSetOrder)
                .toArray();
              data.items = data.items.reverse();

              if (data.items.length != 0) {
                this.listUserInfoEdit[0].listUserInfo[index].listUserField.push(
                  data
                );
              }
            }

            if (this.listUserInfoEdit[0].listUserInfo[index] != undefined) {
              this.listUserInfoEdit[0].listUserInfo[index].listUserField =
                Enumerable.from(
                  this.listUserInfoEdit[0].listUserInfo[index].listUserField
                )
                  .orderByDescending((r) => r.intUserInfoOrder)
                  .toArray();
              this.listUserInfoEdit[0].listUserInfo[index].listUserField =
                this.listUserInfoEdit[0].listUserInfo[
                  index
                ].listUserField.reverse();
            }
          });
        });
        // console.log(this.listUserInfoEdit);

        if (this.listUserInfoEdit.length !== 0) {
          this.blnShowUserInfoEdit = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // process การค้นหาข้อมูลผู้ใช้
    async processSearchUserInfo(keyword) {
      this.progressSearch = 0;
      this.maxcount = 20;
      this.mincount = 0;
      this.blnsorting = true;
      await this.searchUserInfo(keyword)
        .then(() => {
          setTimeout(() => {
            // console.log("progressSearch:100");

            this.listUserInfoClone = [...this.listUserInfo];
            this.listDummyUserinfo = [...this.listUserInfo];
          }, 100);
        })
        .then(() => {
          setTimeout(() => {
            this.progressSearch = 100;
            this.blnsorting = false;
          }, 500);
        });
    },
    // ค้นหาข้อมูลผู้ใช้
    async searchUserInfo(keyword) {
      this.listUserInfo = [];
      this.blnViewUser = false;
      this.strFilterlist = [];
      // this.blnViewUser = false;
      // console.log(keyword);
      // this.ClearFillter();

      try {
        if (keyword == "" || keyword == null) {
          this.clean();
        }
        if (
          keyword != null &&
          keyword != undefined &&
          keyword != "" &&
          keyword.length > 1
        ) {
          this.strSearchWord = keyword;
          // console.log(this.intUsergrouptypeID);
          if (this.intUsergrouptypeID == 1) {
            // นักศึกษา
            this.listUserfieldIDcard = [
              {
                intUserfieldID: [
                  1, 3, 4, 5, 6, 7, 8, 9, 10, 19, 20, 23, 39, 40, 41, 145, 147,
                  148, 149, 150, 151, 152, 153, 154, 161, 163, 236, 237,
                ],
              },
            ];
          } else if (this.intUsergrouptypeID == 2) {
            //พนักงาน
            this.listUserfieldIDcard = [
              {
                intUserfieldID: [
                  // 205, 206, 207, 208, 209, 210,
                  3, 4, 5, 6, 7, 8, 9, 10, 205, 211, 212, 213, 214, 215, 216,
                  218, 219, 221, 222, 226, 228, 236, 237,
                ],
              },
            ];
          }
          // console.log(this.listUserfieldIDcard);
          var res = await axios.post(
            urlapi +
              apiuserinfo.GetUserInfoListByKeyword +
              keyword +
              "/" +
              this.intUsergrouptypeID,
            this.listUserfieldIDcard
          );
          // console.log(res);
          this.listCheckmember = [];
          if (res.data.data != undefined) {
            this.listCheckmember = res.data.data[0].listUserInfoData;
          }

          // console.log(this.$refs.header.$refs.inputSearch.strSearch);
          if (
            this.blnSelectTemplate != true &&
            this.blnOpenform != true &&
            this.blnUserGroupType != false &&
            this.$refs?.header.$refs?.inputSearch?.strSearch != null &&
            this.$refs?.header.$refs?.inputSearch?.strSearch != ""
          ) {
            // this.intSearchResult = await res.data.data.length;
            if (res.data.data != undefined) {
              this.strFilterlist = await res.data.data[0].listFilterData;
              this.listUserInfo = await res.data.data[0].listUserInfoData;
            }
            await this.listSortingdata();
            // console.log(this.listUserInfo);
            if (this.listUserInfo.length == 0) {
              this.blnOpenFilter = true;
              this.blnNotSearch = true;
              this.strFilterlist = [];
            } else if (this.listUserInfo.length != 0) {
              this.blnOpenform = false;
              if (this.device == "mobile") {
                this.blnOpenFilter = false;
              } else {
                this.blnOpenFilter = true;
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
        this.blnAltStatusunsucess = true;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 2000);
      }
      // this.blnNotSearch = true;
    },
    // clear การค้น
    clean() {
      // console.log("clear");
      this.strSearchWord = null;
      this.intSearchResult = 0;
      this.ClearFillter();
    },
    // clear ฟิวเตอร์
    ClearFillter() {
      // console.log("ClearFillter");
      this.blnOpenFilter = false;
      this.strSearchWord = null;
      this.intSearchResult = 0;
      this.blnOpenform = false;
      this.blnNotSearch = false;
      // this.searchUserInfo(null);
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      this.listUserInfo = [];
    },
    // เมื่อกด More information
    closeViewuser() {
      this.blnViewUser = false;
      this.blnShowUserInfoEdit = false;
      this.openform(false);
      this.blnNotSearch = true;
      // console.log(this.listUserInfo);
    },

    //Pagination
    //เปลี่ยนจำนวนข้อมูลต่อ 1 หน้า
    perpageChnage(perPage) {
      this.perpage = perPage;
      //คำนวน จำนวนหน้าทั้งหมด
      this.currentPage = Math.ceil(this.datatotal / perPage);
      this.page = 1;

      //ย้อนกลับไปหน้าแรก
      this.$refs.Pagination.currentPage = 1;
      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        1,
        this.currentData
      );
    },

    //เปลียนหน้า
    onPageChange(page) {
      this.page = page;

      //เรียงลำดับของข้อมูล
      this.listSortData = this.$refs.Pagination.renderTableData(
        page,
        this.currentData
      );
    },

    //คำนวนจำนวนหน้า และจัดเรียงข้อมูล โดยค่าเริ่มต้นเป็น 20 ข้อมูล ต่อ 1 หน้า
    calculatePage(total, data) {
      this.datatotal = total;
      this.currentPage = Math.ceil(total / this.perpage);
      this.page = 1;
      // console.log(this.currentPage);
      this.listSortData = this.$refs.Pagination.renderTableData(1, data);
    },
    // เด้งไปยังหน้าข้อมูลผู้ใช้แบบ Preview
    previewuserinfo(event) {
      // console.log(event);
      // new tab
      const routeData = this.$router.resolve({
        name: "PreviewUser",
        params: {
          memberID: event.intMemberID,
        },
        query: {
          strUsergrouptype: this.strUsergrouptype,
          intUserTypeID: event.typeID,
          keyword: this.strSearchWord,
          intUsergrouptypeID: this.intUsergrouptypeID,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // เลือกประเภทผู้ใช้
    onSelectType(e) {
      // console.log(e);
      this.intUsergrouptypeID = e;
      this.blnUserGroupType = true;
      if (this.intUsergrouptypeID == 1) {
        this.listUserTemplate = [
          {
            strID: 1,
            strUserTemplateName: "Thai student template default",
            strDateTime: "1 มี.ค. 2566",
          },
          {
            strID: 2,
            strUserTemplateName: "Foreign student template default",
            strDateTime: "26 ธ.ค. 2565",
          },
        ];
      } else if (this.intUsergrouptypeID == 2) {
        this.listUserTemplate = [
          {
            strID: 3,
            strUserTemplateName: "Staff template default (Teacher)",
            strDateTime: "1 มี.ค. 2566",
          },
          // {
          //   strID: 4,
          //   strUserTemplateName: "Staff template default",
          //   strDateTime: "1 มี.ค. 2566",
          // },
        ];
      }
      // if (
      //   this.listUserInfo.length != 0 &&
      //   this.listUserInfo.length != undefined
      // ) {
      //   setTimeout(() => {
      //     this.SortBy({ state: "UserID", id: 1 });
      //   }, 1000);
      // }
    },
    // sort ข้อมูลหลังจากค้นหา
    async listSortingdata() {
      let liststatus = [];

      // list Sorting
      if (this.intUsergrouptypeID == 1) {
        this.firstLabel = "19";
        this.endLabel = "89";
        this.listUserInfo.forEach((element) => {
          element.listUserFieldData.forEach((ele) => {
            if (ele.userfieldID == 228) {
              liststatus.push(ele.strData);
              let status = liststatus.filter((e) => {
                if (e != ele.strData) {
                  return e;
                }
              });
              if (status.length == 0) {
                this.listSelect = [
                  {
                    id: 1,
                    lang: "19", //Status
                    state: "Staus",
                    sort: [
                      { id: 1, name: "89" }, //Active
                    ],
                  },
                  {
                    id: 2,
                    lang: "437", //UserID
                    state: "UserID",
                    sort: [
                      { id: 1, name: "435" }, //Descend
                      { id: 2, name: "436" }, //Ascend
                    ],
                  },
                  {
                    id: 3,
                    lang: "438", //Fullname
                    state: "FullnameTH",
                    sort: [
                      { id: 1, name: "ก - ฮ" }, //A - Z
                      { id: 2, name: "ฮ - ก" }, //Z - A
                    ],
                  },
                  {
                    id: 4,
                    lang: "438", //Fullname
                    state: "FullnameEN",
                    sort: [
                      { id: 1, name: "A - Z" }, //A - Z
                      { id: 2, name: "Z - A" }, //Z - A
                    ],
                  },
                ];
              } else {
                this.listSelect = [
                  {
                    id: 1,
                    lang: "19", //Status
                    state: "Staus",
                    sort: [
                      { id: 1, name: "89" }, //Active
                      { id: 2, name: "90" }, //Inactive
                    ],
                  },
                  {
                    id: 2,
                    lang: "437", //UserID
                    state: "UserID",
                    sort: [
                      { id: 1, name: "435" }, //Descend
                      { id: 2, name: "436" }, //Ascend
                    ],
                  },
                  {
                    id: 3,
                    lang: "438", //Fullname
                    state: "FullnameTH",
                    sort: [
                      { id: 1, name: "ก - ฮ" }, //A - Z
                      { id: 2, name: "ฮ - ก" }, //Z - A
                    ],
                  },
                  {
                    id: 4,
                    lang: "438", //Fullname
                    state: "FullnameEN",
                    sort: [
                      { id: 1, name: "A - Z" }, //A - Z
                      { id: 2, name: "Z - A" }, //Z - A
                    ],
                  },
                ];
              }
            } else {
              this.listSelect = [
                {
                  id: 1,
                  lang: "19", //Status
                  state: "Staus",
                  sort: [
                    { id: 1, name: "89" }, //Active
                  ],
                },
                {
                  id: 2,
                  lang: "437", //UserID
                  state: "UserID",
                  sort: [
                    { id: 1, name: "435" }, //Descend
                    { id: 2, name: "436" }, //Ascend
                  ],
                },
                {
                  id: 3,
                  lang: "438", //Fullname
                  state: "FullnameTH",
                  sort: [
                    { id: 1, name: "ก - ฮ" }, //A - Z
                    { id: 2, name: "ฮ - ก" }, //Z - A
                  ],
                },
                {
                  id: 4,
                  lang: "438", //Fullname
                  state: "FullnameEN",
                  sort: [
                    { id: 1, name: "A - Z" }, //A - Z
                    { id: 2, name: "Z - A" }, //Z - A
                  ],
                },
              ];
            }
          });
        });
        await this.SortBy({ state: "Staus", id: 1 });
      } else if (this.intUsergrouptypeID == 2) {
        this.firstLabel = "437";
        this.endLabel = "435";
        this.listSelect = [
          {
            id: 2,
            lang: "437", //UserID
            state: "UserID",
            sort: [
              { id: 1, name: "435" }, //Descend
              { id: 2, name: "436" }, //Ascend
            ],
          },
          {
            id: 3,
            lang: "438", //Fullname
            state: "FullnameTH",
            sort: [
              { id: 1, name: "ก - ฮ" }, //A - Z
              { id: 2, name: "ฮ - ก" }, //Z - A
            ],
          },
          {
            id: 4,
            lang: "438", //Fullname
            state: "FullnameEN",
            sort: [
              { id: 1, name: "A - Z" }, //A - Z
              { id: 2, name: "Z - A" }, //Z - A
            ],
          },
        ];
        await this.SortBy({ state: "UserID", id: 1 });
      }
    },
    // lazyload
    handleScroll(e) {
      // console.log(e.target.scrollTop);
      if (
        e.target.scrollTop + 80 * 10 >= e.target.scrollHeight &&
        this.maxcount >= 20
      ) {
        this.maxcount = this.maxcount + 20;
        // console.log(this.maxcount);
      }
    },
    // เลือกเทมเพลต
    selectedTemplate(templateID) {
      // console.log(templateID, this.action);
      if (this.action == "Insert") {
        this.intUserTemplateID = templateID;
        this.blnSelectTemplate = false;
      }
    },
    //ตรวจสอบข้อมูลผู้ใช้มีอยู่แล้ว เด้งไปที่การอัพเดท
    async gotoUpdate(event) {
      let editData = event;
      this.blnOpenform = false;
      await this.searchUserInfo(event.strStudentCode);
      // console.log(event, this.listCheckmember);
      editData.intUserTemplateID = this.listCheckmember?.[0].intUserTemplateID;
      editData.typeID = this.listCheckmember?.[0].intUserTypeID;
      this.strSearchWord = "";
      await this.editUserInfo(editData);
    },
  },
  computed: {
    // อัพเทด listUserInfo
    ListUserInfo() {
      return this.listUserInfo;
    },
    // อัพเดท lenght lazyload
    listUserInfoCrop() {
      return Object.values(this.listUserInfoClone).slice(
        this.mincount,
        this.maxcount
      );
    },
  },
};
</script>
<style lang="scss">
.selected-template {
  cursor: pointer;
}
.userInfo {
  .left,
  .right {
    height: calc(100vh - 301px);
    overflow-y: auto;
    position: sticky;
  }
  .show-filter-mobile {
    right: 0 !important;
  }
  .filter-mobile {
    transition: 150ms;
    position: fixed;
    height: 100vh;
    width: 85%;
    right: -85%;
    top: 0;
    bottom: 0;
    z-index: 9999;
  }
  .show-overlay-filter-mobile {
    visibility: visible !important;
    opacity: 1 !important;
  }
  .overlay-filter-mobile {
    position: fixed;
    transition: 150ms;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 9000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  }
  .card {
    border-radius: 4px;
    border: 1px solid $black-400;
  }
}
</style>
