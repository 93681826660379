<template>
  <div
    class="bg-white shadow-sm rounded select-form"
    :class="device == 'mobile' ? 'w-100 p-4' : 'p-5'"
    style="user-select: none"
  >
    <p class="fnt-medium my-3" :class="device == 'mobile' ? 'fnt-body' : ''">
      {{ $t("1103") }}
    </p>
    <div
      class="d-flex gap-3 justify-content-satrt"
      :class="device == 'mobile' ? 'flex-column' : ''"
    >
      <div
        v-for="(item, index) in listTypeForm"
        :key="index"
        class="card-template border rounded p-4 d-flex align-items-center flex-column"
        :class="
          device == 'mobile'
            ? item.id == 3
              ? 'bg-black-200 pe-none opacity-75 w-100 h-100'
              : 'w-100 h-100'
            : item.id == 3
            ? 'bg-black-200 pe-none opacity-75'
            : ''
        "
        :id="item.id"
        @click="selectForn(item.id, item.Usergrouptype)"
      >
        <div
          class="d-flex flex-column algin-items-center justify-content-center text-center h-100 mt-2 pt-1"
        >
          <div class="h-100 d-flex align-items-center justify-content-center">
            <DrafFile width="52" class="fill-black-500" />
          </div>
          <div class="h-100" :class="device == 'mobile' ? 'fnt-body' : ''">
            {{ $t(item.name) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DrafFile } from "@/components/icon/index";
export default {
  components: { DrafFile },
  data() {
    return {
      listTypeForm: [
        {
          id: 1,
          name: "450",
          Usergrouptype: "Student",
        },
        {
          id: 2,
          name: "1101",
          Usergrouptype: "Officer",
        },
        {
          id: 3,
          name: "452",
          Usergrouptype: "Outsider",
        },
      ],
    };
  },
  emits: ["close"],
  methods: {
    selectForn(id, Usergrouptype) {
      // console.log(id);
      this.$emit("UsergrouptypeID", id);
      this.$emit("UsergrouptypeName", Usergrouptype);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-form {
  width: fit-content;
  .card-template {
    width: 350px;
    height: 150px;
    border-color: $black-500 !important;
    cursor: pointer;
    &:hover {
      background-color: $black-100;
    }
  }
  .black-form {
    cursor: pointer;
    width: fit-content;
    &:hover {
      text-decoration: underline;
      color: $secondary;
    }
    .black-icon {
      transform: rotate(90deg);
    }
  }
}
</style>