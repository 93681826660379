<template>
  <div
    class="popup-container align-items-center"
    v-show="blnGroupchange == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnGroupchange"
        :altMsgBox="$t('1026')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('214')"
        :strButtonCancel="$t('215')"
        @confirm="ConfirmchangeGroup($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnGroupchange"
        @click="blnGroupchange = true"
      ></div>
    </transition>
  </div>
  <div class="popup-container align-items-center" v-show="blnAltcancel == true">
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltcancel == true"
        :altMsgBox="$t('216')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmAltPopup($event)"
        classIcon="text-secondary fill-secondary"
        :strButtonSubmit="'218'"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltcancel == true"
        @click="blnAltcancel = false"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopUpCheckmember == true"
  >
    <transition name="zoom">
      <AlertPopup
        :altMsgBox="$t(strPopUpCheckmember)"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmCheckmember($event)"
        classIcon="text-secondary fill-secondary"
        :strButtonSubmit="'214'"
      />
    </transition>
    <transition name="fade">
      <div class="overlay" @click="blnAltcancel = false"></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t(strSMSGunsucess)"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltunsucess ? 'show' : ''"
  />
  <div
    class="popup-container align-items-center"
    v-if="blnAltStatusunsucess == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltStatusunsucess == true"
        :altMsgBox="$t('174')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        :strButtonSubmit="$t('886')"
        :strButtonCancel="$t('35')"
        @confirm="reloaddata($event)"
        classIcon="text-secondary fill-secondary"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltStatusunsucess == true"
        @click="blnAltStatusunsucess = true"
      ></div>
    </transition>
  </div>
  <div v-if="progress != 100">
    <Loading1 />
  </div>
  <div v-if="progress == 100" class="d-flex w-100 m-0">
    <div
      v-if="device != 'mobile'"
      class="bg-white ps-5 pe-2 py-4 shadow-sm rounded left-menu me-3 w-25"
      style="height: fit-content"
    >
      <p class="text-primary fnt-medium mb-4">{{ $t("910") }}</p>
      <div
        class="mb-4 menu d-flex"
        :class="
          !item.blnInsert && newaction == 'Insert'
            ? 'pe-none disabled text-black-500'
            : ''
        "
        v-for="(item, index) in listMenu"
        :key="index"
        @click="selectMenu(item.intGroupID, index)"
      >
        <span
          class="fnt-light mx-2 ms-3"
          :class="intindexOfGroupID == index ? 'text-primary fnt-medium' : ''"
          >{{ $t(item.strGroupName) }}</span
        >
      </div>
    </div>

    <div class="rounded right-menu w-100">
      <div class="bg-white shadow-sm rounded p-5 mb-3" v-if="blnLoading">
        <!-- <Loading1 /> -->
        <div
          class="rounded-2 skeleton-item bg-black-200 mb-3"
          style="height: 70px; width: 100%"
        ></div>
        <div
          class="rounded-2 skeleton-item bg-black-200"
          style="height: 70px; width: 100%"
        ></div>
      </div>
      <div class="w-100" v-if="intUsergrouptypeID != 3 && !blnLoading">
        <Cards
          :listuserinfoData="listUserInfoOnEdit"
          :blnShowIndex="false"
          :blnHidEdit="true"
          :blnHidPreview="true"
          :strAction="Action"
          ref="CardUsefix"
        />
      </div>
      <div
        class="bg-white rounded shadow-sm my-2 w-100"
        v-if="device == 'mobile'"
      >
        <Select
          :listItem="listSelect"
          :SelectedValue="1"
          background="bg-white border"
          size="w-100 medium"
          @input="onSelect($event)"
        />
      </div>
      <div class="bg-white shadow-sm rounded" v-if="blnLoading">
        <Loading1 />
      </div>
      <div class="bg-white shadow-sm rounded" v-if="!blnLoading">
        <div
          class="fnt-medium fnt-title bg-white fnt-bold text-dark w-100 shadow-sm rounded-top border-bottom"
          :class="device == 'mobile' ? 'p-3' : 'px-5 py-4'"
        >
          <!-- name -->
          <div v-if="!blnLoading">
            <div class="d-flex justify-content-between align-items-center">
              <span>
                {{
                  $t(
                    listUserFields[
                      intUserTemplateID == 4 ? 2 : intUserTemplateID - 1
                    ][intindexOfGroupID].formName
                  )
                }}
              </span>
              <!-- <span v-if="Action == 'Insert'">
                <inputSearch
                  mode="enter"
                  :hint="
                    $t('447', {
                      fieldname: $t(
                        intUserTemplateID == 1 || intUserTemplateID == 2
                          ? '638'
                          : '1002'
                      ).toLocaleLowerCase(),
                    })
                  "
                  :maxlength="
                    intUserTemplateID == 1 || intUserTemplateID == 2
                      ? '8'
                      : '10'
                  "
                  validate="number"
                  :TooltipMsg="
                    intUserTemplateID == 1 || intUserTemplateID == 2
                      ? 'กรุณากรอกรหัสนักศึกษาให้ครบ 8 ตัว'
                      : 'กรุณากรอกรหัสพนักงานให้ครบ 10 ตัว'
                  "
                  @onSearch="checkMember($event)"
                  ref="inputSearch"
                />
              </span> -->
            </div>

            <!-- <span class="">
              <CorrectFill
                width="16"
                :class="blnCorrect == false ? 'fill-black-500' : 'fill-success'"
              />
            </span> -->
          </div>
          <div
            v-else
            class="rounded-2 skeleton-item bg-black-200"
            style="height: 48px; width: 200px"
          ></div>
        </div>

        <div
          class="bg-white rounded-bottom shadow-sm"
          :class="device == 'mobile' ? 'p-3' : 'px-5 py-6 mb-4'"
        >
          <InputAddGroupList
            :formData="
              listUserFields[
                intUserTemplateID == 4 ? 2 : intUserTemplateID - 1
              ][intindexOfGroupID]
            "
            :blnDisable="false"
            :userInfo="userInfo"
            @userInfoChange="updateData($event)"
            @keywordfromUpdate="keywordfromUpdate($event)"
            @checkMember="checkMemberData($event)"
            @disbleSubmit="disbleSubmit($event)"
            @changUserType="changUserType($event)"
            :blnAddData="blnaddData"
            :ref="'UserInfoForm'"
            :blnLoading="blnLoading"
            :indexMenu="intindexOfGroupID"
            :Action="Action"
            :strMenuID="intGroupID"
            :intUserTypeID="intUserType"
          />
          <div class="mt-6 d-flex">
            <!-- <a :href="'#' + ScrollRepeats"> -->
            <Button
              @click="addData()"
              class="btn btn-fix me-3"
              :class="
                blnCheckData == true
                  ? 'disable-submit bg-black-300 text-white pe-none'
                  : 'btn-primary'
              "
              :name="$t('36')"
              :id="'submitUserInfo' + this.intGroupID"
            />
            <!-- </a> -->

            <Button
              @click="closeForm()"
              class="btn-cancel btn-fix text-primary"
              :name="$t('35')"
            />
          </div>
          <div class="w-100 mt-4" v-if="inactive !== true">
            <DeleteInform
              icon="DeleteFill"
              classBtnIcon="fill-secondary "
              class="mt-5"
              @input="deleteData()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from "@/components/cards/userFix.vue";
import Loading1 from "@/components/Skeleton.vue";
import AltStatus from "@/components/alert/alertStatus.vue";
import apitemplate from "@/typescript/urlapi/adminsystem/APITemplate";
import apiuserinfo from "@/typescript/urlapi/adminsystem/APIUserInfo";
import urlapi from "@/typescript/urlapi/url";
import AlertPopup from "@/components/alert/alertPopup.vue";
import axios from "axios";
import { listUserFieldsID, listUserFields } from "@/typescript/templateData.ts";
import InputAddGroupList from "@/components/input/InputAddGroupList.vue";
import Select from "@/components/input/Select/Select.vue";
import Button from "@/components/button/button.vue";
import AdsInventories from "@/typescript/inventory";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
// import inputSearch from "@/components/input/inputSearch.vue";
import Enumerable from "linq";
export default {
  components: {
    Cards,
    Loading1,
    InputAddGroupList,
    Select,
    Button,
    AltStatus,
    AlertPopup,
    // inputSearch,
  },
  props: {
    intUserTypeID: { type: Number },
    intMemberID: { type: Number },
    listUserInfo: { type: Array },
    Action: { type: String },
    newaction: { type: String },
    intUserTemplateID: { type: Number },
    intUsergrouptypeID: { type: String },
  },
  data() {
    return {
      blnCheckData: false,
      listcheckDisable: [],
      memberStatus: "0",
      intmemberID: null,
      strSMSGsuccess: "การบันทึกสำเร็จ",
      listUserFields: listUserFields,
      listUserFieldsID: listUserFieldsID,
      blnAltStatus: false,
      listMenu: [],
      userInfo: [],
      intGroupID: 1,
      listfrirsttimedata: [],
      intindexOfGroupID: 0,
      progress: 0,
      listSelect: [],
      blnLoading: false,
      keywordFromUpdate: "",
      blnAltunsucess: false,
      strSMSGunsucess: "174",
      blnaddData: false,
      NewlistUserinfo: [],
      DummyListUserinfoID: [],
      intdataChange: 0,
      blnGroupchange: false,
      blnAltcancel: false,
      intOldGroupID: 1,
      intOldindex: 0,
      dummy: [],
      defaultdata: [],
      listUserInfoOnEdit: [],
      studentID: "",
      listUserfieldIDcard: [
        {
          intUserfieldID: [
            1, 3, 4, 5, 6, 7, 8, 9, 10, 19, 20, 23, 39, 40, 41, 145, 147, 148,
            149, 150, 151, 152, 153, 154, 161, 163, 205, 206, 207, 208, 209,
            210, 211, 212, 213, 214, 215, 216, 218, 219, 221, 222, 226, 228,
          ],
        },
      ],
      blnPopUpCheckmember: false,
      strPopUpCheckmember: "",
      listMemberData: [],
      action: "",
      listUpdateData: [],
      intUserType: 0,
      intInfoID223: 0,
      strURLUpdateProfileIMG: "",
      strUserCode: "",
      blnAltStatusunsucess: false,
    };
  },
  mounted() {
    // Insert จะยังไม่มี userType
    if (this.intUserTypeID == "") {
      // ถ้าเลือกเทมเพลต 3 หรือ 4 จะให้ userType เป็น 4 ก่อน
      if (this.intUserTemplateID == 3 || this.intUserTemplateID == 4) {
        this.intUserType = 4;
      } else {
        // ถ้าไม่เลือกเทมเพลต 3 หรือ 4 จะให้ userType ตามเทมเพลตที่เลือก
        this.intUserType = this.intUserTemplateID;
      }
    } else {
      this.intUserType = this.intUserTypeID;
    }
    // console.log(this.intUserTemplateID);
    // console.log(this.intUserType);
    this.intmemberID = this.intMemberID;
    if (this.intUserTemplateID == 1) {
      this.strTemplateType = "Thai student template default";
      this.listMenu = [
        { intGroupID: 1, blnInsert: true, strGroupName: "911" },
        { intGroupID: 2, blnInsert: false, strGroupName: "912" },
        { intGroupID: 3, blnInsert: false, strGroupName: "913" },
        { intGroupID: 4, blnInsert: false, strGroupName: "914" },
        { intGroupID: 5, blnInsert: false, strGroupName: "915" },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { intGroupID: 6, strGroupName: "916" },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 2, name: "912" },
        { id: 3, name: "913" },
        { id: 4, name: "914" },
        { id: 5, name: "915" },
        // ข้อมูลบัณฑิตยังไม่มีใน phase 1
        // { id: 6, name: "916" },
      ];
    } else if (this.intUserTemplateID == 2) {
      this.strTemplateType = "Foreign student template default";
      this.listMenu = [
        { intGroupID: 1, blnInsert: true, strGroupName: "911" },
        { intGroupID: 3, blnInsert: false, strGroupName: "913" },
        { intGroupID: 2, blnInsert: false, strGroupName: "912" },
        {
          intGroupID: 8,
          strGroupName: "993",
        },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 3, name: "913" },
        { id: 2, name: "912" },
        { id: 8, name: "993" },
      ];
    } else if (this.intUserTemplateID == 3 || this.intUserTemplateID == 4) {
      this.strTemplateType = "Staff template default";
      this.listMenu = [
        { intGroupID: 1, blnInsert: true, strGroupName: "911" },
        { intGroupID: 9, blnInsert: false, strGroupName: "1003" },
      ];
      this.listSelect = [
        { id: 1, name: "911" },
        { id: 9, name: "1003" },
      ];
    }
    // console.log("mounted");
    this.progress = 0;
    // this.blnLoading = true;
    this.processLoadingData().then(() => {
      // this.blnLoading = false;
      this.progress = 100;
    });
    this.blnCheckData = true;
  },
  methods: {
    // popUp ยืนยันการโหลดข้อมูลใหม่เมื่อมีปัญหา API
    reloaddata(event) {
      if (event == true) {
        this.progress = 0;
        this.blnLoading = true;
        this.processLoadingData().then(() => {
          this.blnLoading = false;
        });

        setTimeout(() => {
          this.progress = 100;
        }, 1000);
        this.blnAltStatusunsucess = false;
      }
      if (event == false) {
        this.blnAltStatusunsucess = false;
      }
      this.blnAltStatusunsucess = false;
    },
    // filter ข้อมูลเพื่อหารหัสแล้วนำไปค้นหาUserInfo
    filterCardData() {
      this.listUserInfo.forEach((element) => {
        if (element.intMemberID == this.intmemberID) {
          // console.log(element);
          this.listUserInfoOnEdit = [];
          this.listUserInfoOnEdit.push(element);
          // console.log(this.listUserInfoOnEdit);
          this.listUserInfoOnEdit[0].listUserFieldData.forEach((ele) => {
            if (
              ele.userfieldID == 1 ||
              ele.userfieldID == 145 ||
              ele.userfieldID == 205
            ) {
              this.studentID = ele.strData;
              // console.log(this.studentID);
              this.searchUserInfo(this.studentID);
            }
          });
        }
      });
    },
    // popUp ยืนยันการเปลี่ยนกลุ่ม
    async ConfirmchangeGroup(e) {
      if (e == true) {
        this.blnGroupchange = false;
        await this.addData();
        this.blnaddData = false;
        this.blnLoading = true;
        await this.fetchUserfields(this.intOldindex);
        await this.fetchUserInfo(this.intOldGroupID);
        await this.fixCountry();
        this.intGroupID = this.intOldGroupID;
        this.intindexOfGroupID = this.intOldindex;
        setTimeout(() => {
          this.blnLoading = false;
        }, 1000);
      } else if (e == false) {
        this.blnLoading = true;
        this.blnGroupchange = false;
        await this.fetchUserfields(this.intOldindex);
        await this.fetchUserInfo(this.intOldGroupID);
        await this.fixCountry();
        this.intGroupID = this.intOldGroupID;
        this.intindexOfGroupID = this.intOldindex;

        setTimeout(() => {
          this.blnLoading = false;
        }, 1000);
      }
    },
    // popUp ปิดฟอร์ม
    confirmAltPopup(event) {
      if (event == true) {
        this.$emit("closeForm", false);
      } else if (event == false) {
        this.blnAltcancel = false;
      }
    },
    //skeleton load ข้อมูล
    async processLoadingData() {
      this.processData = true;
      await this.fetchUserfields(this.intindexOfGroupID);
      if (this.listUserInfo != [] && this.intmemberID != null) {
        await this.filterCardData();
        await this.fetchUserInfo(this.intGroupID);
      }
      this.fixCountry();

      setTimeout(() => {
        this.processData = false;
      }, 1000);
    },
    // เลือกเมนู
    async selectMenu(intGroupID, indexOfGroupID) {
      //modify new model to compare with dummylist
      this.defaultdata = [];
      this.userInfo.forEach((ele) => {
        if (typeof ele.data === "object") {
          if (ele.intUserFieldID == 40) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[0]?.toString(),
            });
          } else if (ele.intUserFieldID == 41) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[1]?.toString(),
            });
          } else {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id?.toString(),
            });
          }
        } else {
          // console.log(ele)
          this.defaultdata.push({
            intUserFieldID: ele.intUserFieldID,
            intUserInfoID: ele.intUserInfoID,
            data: ele.data,
          });
        }
      });
      //select data from default data
      // let defaultdata = Enumerable.from(this.DummyListUserinfoID)
      //   .select((s) => s.data)
      //   .toArray();
      // //select data from updated data
      // let newdata = Enumerable.from(this.defaultdata)
      //   .select((s) => s.data)
      //   .toArray();
      // let result = Enumerable.from(defaultdata).except(newdata).toArray();
      // console.log(this.DummyListUserinfoID);
      // console.log(this.listUpdateData);
      //gruop change check
      if (
        this.intGroupID !== intGroupID &&
        this.intindexOfGroupID !== indexOfGroupID
      ) {
        let count = 0;
        if (this.DummyListUserinfoID.length != 0) {
          // มีข้อมูลเดิมอยู่แล้ว
          // console.log(this.DummyListUserinfoID);
          // console.log(this.listUpdateData);
          this.DummyListUserinfoID.forEach((element) => {
            this.listUpdateData.forEach((ele) => {
              if (
                element.intUserFieldID == ele.userInfo.intUserFieldID &&
                element.intGroupLink == ele.userInfo.intGroupLink
              ) {
                // console.log(element, ele.userInfo);
                if (ele.userInfo.data?.id == undefined) {
                  if (element.data != ele.userInfo.data) {
                    // console.log(
                    //   element.intUserFieldID,
                    //   element.data,
                    //   ele.userInfo.data
                    // );
                    count++;
                  }
                } else {
                  if (element.data != ele.userInfo.data.id) {
                    // console.log(
                    //   element.intUserFieldID,
                    //   element.data,
                    //   ele.userInfo.data
                    // );
                    count++;
                  }
                }
              }
            });
          });
          // ตรวจสอบค่าที่ไม่มีจาก api (เพิ่มเติมจาก api)
          const result = this.listUpdateData.filter(
            (item2) =>
              !this.DummyListUserinfoID.some(
                (item1) =>
                  item1.intUserFieldID === item2.userInfo.intUserFieldID
              )
          );

          // filter ค่า null ออก
          const filteredData = result.filter(
            (item) => item.userInfo.data !== null && item.userInfo.data != "0"
          );
          if (filteredData.length > 0) {
            count++;
          }
        } else {
          // ไม่มีข้อมูลเดิม เป็นการเพิ่มไปใหม่
          this.listUpdateData.forEach((ele) => {
            if (
              ele.userInfo.data != null &&
              ele.userInfo.data != "" &&
              ele.userInfo.data.id != 214 &&
              ele.userInfo.data !=
                '{"value":{"id":null,"lang":{"langEN":"0","langTH":"0"}}}'
            ) {
              // console.log(ele.userInfo.intUserFieldID, ele.userInfo.data);
              count++;
            }
          });
        }

        // console.log(count);
        if (count != 0) {
          this.blnGroupchange = true;
          this.intOldGroupID = intGroupID;
          this.intOldindex = indexOfGroupID;
        } else {
          this.blnLoading = true;
          await this.fetchUserfields(indexOfGroupID);
          if (this.intmemberID != null) {
            await this.fetchUserInfo(intGroupID);
          }
          this.intGroupID = intGroupID;
          this.intindexOfGroupID = indexOfGroupID;
          setTimeout(() => {
            this.blnLoading = false;
          }, 1000);
        }
        // if (
        //   (result != "" && this.dummy == "") ||
        //   (result != "" && this.dummy != "")
        // ) {
        //   this.blnGroupchange = true;
        //   this.intOldGroupID = intGroupID;
        //   this.intOldindex = indexOfGroupID;
        // } else {

        // }
      }
      this.fixCountry();
    },
    // fix ประเทศในบาง userfield
    fixCountry() {
      // console.log("fixCountry", this.Action);
      // fix ประเทศไทย
      if (this.Action == "Insert" || this.Action == "Update_special") {
        // console.log(this.intUserTemplateID);
        if (this.intUserTemplateID == 1) {
          let listFixCountry = [
            {
              intUserFieldID: 27,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 34,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 69,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 76,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 103,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 130,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
          ];
          listFixCountry.forEach((ele) => {
            this.userInfo.push(ele);
          });

          // default สัญชาติ เชื้อชาติ
          if (this.Action == "Insert") {
            let listNation = [
              {
                intUserFieldID: 15,
                intUserInfoID: 0,
                data: JSON.stringify({
                  value: { id: null, lang: { langEN: "Thai", langTH: "ไทย" } },
                }),
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 16,
                intUserInfoID: 0,
                data: JSON.stringify({
                  value: { id: null, lang: { langEN: "Thai", langTH: "ไทย" } },
                }),
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
            ];
            listNation.forEach((ele) => {
              this.userInfo.push(ele);
            });
            // console.log(listNation, this.userInfo);
          }
        } else if (this.intUserTemplateID == 2) {
          let listFixCountry = [
            {
              intUserFieldID: 166,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 175,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: 189,
              intUserInfoID: 0,
              data: 214,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
          ];
          listFixCountry.forEach((ele) => {
            this.userInfo.push(ele);
          });
        }
      }
    },
    // ดึงข้อมูล Userfield ตาม group
    async fetchUserfields(intindexOfGroupID) {
      // console.log("res");
      // console.log(this.intUserTemplateID);

      let intUserTemplate = this.intUserTemplateID;
      intUserTemplate == 4 ? (intUserTemplate = 3) : intUserTemplate;
      try {
        // apitemplate
        const res = await axios.post(
          urlapi + apitemplate.getUserFields,
          this.listUserFieldsID[intUserTemplate - 1][intindexOfGroupID]
        );
        // console.log(
        //   urlapi + apitemplate.getUserFields,
        //   this.listUserFieldsID[intUserTemplate - 1][intindexOfGroupID]
        // );
        // console.log(res);
        this.listcheckDisable = res.data.dataFields;
        this.listUserFields?.[intUserTemplate - 1][
          intindexOfGroupID
        ].fieldsGroup.forEach((group, intGroupIndex) => {
          group.fieldList?.[0]?.forEach((row, intRowIndex) => {
            row.forEach((column, intColumnIndex) => {
              res.data.dataFields?.forEach((field) => {
                if (field.strID == column.strID) {
                  this.listUserFields[intUserTemplate - 1][
                    intindexOfGroupID
                  ].fieldsGroup[intGroupIndex].fieldList[0][intRowIndex][
                    intColumnIndex
                  ] = {
                    ...this.listUserFields[intUserTemplate - 1][
                      intindexOfGroupID
                    ].fieldsGroup[intGroupIndex].fieldList[0][intRowIndex][
                      intColumnIndex
                    ],
                    ...field,
                  };
                  // console.log(this.listUserFields[0][this.intGroupID - 1].fieldsGroup[intGroupIndex].fieldList[intRowIndex][intColumnIndex]);
                }
              });
            });
          });
        });
        if (res.data.code == 500) {
          // this.blnAltStatusunsucess = true;
          this.blnAltunsucess = true;
          this.strSMSGunsucess = "174";
          setTimeout(() => {
            this.blnAltunsucess = false;
          }, 5000);
        }
        // console.log(res.data.dataFields);
      } catch (error) {
        console.log(error);
        this.blnAltunsucess = true;
        this.strSMSGunsucess = "174";
        setTimeout(() => {
          this.blnAltunsucess = false;
        }, 5000);

        // this.blnAltStatusunsucess = true;
      }
    },
    // ดึงข้อมูล UserInfo ตาม group
    async fetchUserInfo(intGroupID) {
      try {
        // console.log(urlapi + apiuserinfo.LoadUserinfobyID + this.intmemberID);
        // if (this.intMemberID != null) {
        const res = await axios.get(
          urlapi + apiuserinfo.LoadUserinfobyID + this.intmemberID,
          {
            params: { UserInfoID: intGroupID },
          }
        );
        // console.log(urlapi + apiuserinfo.LoadUserinfobyID + this.intmemberID, {
        //   params: { UserInfoID: intGroupID },
        // });
        // console.log(res);

        this.userInfo = res.data.data.userinfo;
        this.DummyListUserinfoID = [];
        this.intInfoID223 = 0;
        this.strURLUpdateProfileIMG = "";
        this.strUserCode = "";
        res.data.data.userinfo.forEach((ele) => {
          this.DummyListUserinfoID.push({
            intUserFieldID: ele.intUserFieldID,
            intUserInfoID: ele.intUserInfoID,
            data: ele.data,
            strDataImage: ele.strDataImage == undefined ? "" : ele.strDataImage,
            intGroupLink: ele.intGroupLink,
          });

          if (ele.intUserFieldID == 223) {
            this.intInfoID223 = ele.intUserInfoID;
          }

          // รูปภาพโปรไฟล์
          if (
            ele.intUserFieldID == 19 ||
            ele.intUserFieldID == 163 ||
            ele.intUserFieldID == 216
          ) {
            this.strURLUpdateProfileIMG = ele.strDataImage;
          }

          // รหัสนักศึกษา/รหัสพนักงาน
          if (ele.intUserFieldID == 1 || ele.intUserFieldID == 205) {
            this.strUserCode = ele.data;
          }
        });
        // console.log(this.DummyListUserinfoID);

        // }
      } catch (error) {
        console.error(error, intGroupID);
      }
    },
    // ตรวจสอบข้อมูลถูกต้องหรือไม่จากฟอร์มก่อนนำค่าไปอัพเดท
    updateData(event) {
      // console.log(event);
      // console.log(this.DummyListUserinfoID);

      this.NewlistUserinfo = [];
      this.intdataChange = 0;
      for (let i = 0; i < event.length; i++) {
        const element = event[i];
        // console.log(element.userInfo);
        if (element.userInfo.data == null) {
          element.userInfo.data = "";
        }
        if (element.userInfo.strDataImage == null) {
          element.userInfo.strDataImage = "";
        }
        if (
          element.userInfo.intUserInfoID == "" ||
          element.userInfo.intUserInfoID == null
        ) {
          element.userInfo.intUserInfoID = 0;
          this.intdataChange++;
        }
        if (element.userInfo.intUserFieldID == 40) {
          if (
            element.userInfo.data != "" &&
            element.userInfo.data.id != undefined
          ) {
            element.userInfo.data =
              element.userInfo?.data?.id[0] == undefined
                ? element.userInfo?.data?.id.toString()
                : element.userInfo?.data?.id[0].toString();
            // element.userInfo.data = element.userInfo.data.toString()
          }
        }
        if (element.userInfo.intUserFieldID == 41) {
          if (
            element.userInfo.data != "" &&
            element.userInfo.data.id != undefined
          ) {
            element.userInfo.data =
              element.userInfo?.data?.id[1] == undefined
                ? element.userInfo?.data?.id.toString()
                : element.userInfo?.data?.id[1].toString();
            // element.userInfo.data = element.userInfo.data.toString()
          }
        }
        // if (element.userInfo.data == "{}") {
        //   console.log(element.userInfo.data);
        //   break;
        // }
        if (element.userInfo?.data?.id != undefined) {
          let id = element.userInfo.data.id;
          element.userInfo.data = id.toString();
          // console.log(element.userInfo.data);
        }
        if (element.userInfo?.data?.strID != undefined) {
          let strID = element.userInfo.data.strID;
          element.userInfo.data = strID.toString();
          // console.log(element.userInfo.data);
        }

        // ตรวจUserinfo ID อีกรอบ
        this.DummyListUserinfoID.forEach((dummy) => {
          if (element.userInfo.intUserFieldID == dummy.intUserFieldID) {
            if (
              element.userInfo.intUserFieldID != 49 &&
              element.userInfo.intUserFieldID != 50 &&
              element.userInfo.intUserFieldID != 51 &&
              element.userInfo.intUserFieldID != 52 &&
              element.userInfo.intUserFieldID != 217 &&
              element.userInfo.intUserFieldID != 218 &&
              element.userInfo.intUserFieldID != 219 &&
              element.userInfo.intUserFieldID != 220 &&
              element.userInfo.intUserFieldID != 221 &&
              element.userInfo.intUserFieldID != 222
            ) {
              element.userInfo.intUserInfoID = dummy.intUserInfoID;
            }

            // ตรวจสอบข้อมูลมีการเปลี่ยนแปลงหรือไม่
            if (element.userInfo.data != dummy.data) {
              this.intdataChange++;
              // ข้อมูลเปลี่ยน
              // console.log(
              //   element.userInfo.intUserFieldID,
              //   element.userInfo.data
              // );
            }
            // console.log(element.userInfo,dummy)
            if (element.userInfo.strDataImage != dummy.strDataImage) {
              this.intdataChange++;
            }
          }
        });

        if (element.userInfo.intGroupLink == undefined) {
          element.userInfo["intGroupLink"] = 0;
        }
        this.NewlistUserinfo.push(element.userInfo);
      }
      // console.log(this.NewlistUserinfo);
      // ตรวจสอบเฉพาะเมนูข้อมูลตำแหน่ง
      if (this.intGroupID == 9) {
        // ตรวจสอบว่ามี ไอดี 223 ไหม หากยังไม่มีให้เพิ่มไป
        var hasIntUserFieldID223 = this.NewlistUserinfo.some((obj) => {
          return obj.intUserFieldID === 223;
        });
        if (!hasIntUserFieldID223) {
          this.NewlistUserinfo.push({
            intUserFieldID: 223,
            intUserInfoID: this.intInfoID223,
            data: this.intUserType.toString(),
            strDataImage: "",
            intOrder: 0,
            intDataGroupType: 0,
            intGroupLink: 0,
          });
        }
      }

      // console.log(this.DummyListUserinfoID);
    },
    // เลือกเมนูของ mobile
    async onSelect(event) {
      // console.log(event);
      // this.progress = true;
      // await this.fetchUserfields(event.index);
      // await this.fetchUserInfo(event.id);
      // this.intGroupID = event.id;
      // this.intindexOfGroupID = event.index;
      // setTimeout(() => {
      //   this.progress = false;
      //   this.blnLoading = false;
      // }, 1000);

      //modify new model to compare with dummylist
      this.defaultdata = [];
      this.userInfo.forEach((ele) => {
        if (typeof ele.data === "object") {
          if (ele.intUserFieldID == 40) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[0].toString(),
            });
          } else if (ele.intUserFieldID == 41) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[1].toString(),
            });
          } else {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id.toString(),
            });
          }
        } else {
          // console.log(ele)
          this.defaultdata.push({
            intUserFieldID: ele.intUserFieldID,
            intUserInfoID: ele.intUserInfoID,
            data: ele.data,
          });
        }
      });
      //select data from default data
      let defaultdata = Enumerable.from(this.DummyListUserinfoID)
        .select((s) => s.data)
        .toArray();
      //select data from updated data
      let newdata = Enumerable.from(this.defaultdata)
        .select((s) => s.data)
        .toArray();
      let result = Enumerable.from(defaultdata).except(newdata).toArray();
      //gruop change check
      if (
        this.intGroupID !== event.id &&
        this.intindexOfGroupID !== event.index
      ) {
        if (
          (result != "" && this.dummy == "") ||
          (result != "" && this.dummy != "")
        ) {
          this.blnGroupchange = true;
          this.intOldGroupID = event.id;
          this.intOldindex = event.index;
        } else {
          this.blnLoading = true;
          await this.fetchUserfields(event.index);
          await this.fetchUserInfo(event.id);
          this.intGroupID = event.id;
          this.intindexOfGroupID = event.index;
          setTimeout(() => {
            this.blnLoading = false;
          }, 1000);
        }
      }
    },
    // keyword จากการอัพเดทเพื่อนำไปค้นหาหลังจากอัพเดทเสร็จ
    keywordfromUpdate(keyword) {
      this.keywordFromUpdate = keyword;
    },
    // เพิ่มข้อมูล ข้อมูลผู้ใช้
    async addData() {
      // console.log("addData");
      this.blnaddData = true;
      // console.log(this.$refs.UserInfoForm.detectDatainField());
      // ตรวจสอบข้อมูลที่จำเป็นต้องใส่
      if ((await this.$refs.UserInfoForm.detectDatainField()) == true) {
        this.blnCheckData = true;
        if (this.intdataChange != 0) {
          try {
            // console.log(params);
            // console.log(this.intGroupID);
            // let oldintGroupID = this.intGroupID;
            // let oldintindexOfGroupID = this.intindexOfGroupID;
            if (
              (this.Action == "Update" || this.Action == "Update_special") &&
              this.newaction == "Update"
            ) {
              // ข้อมูลมีการเปลี่ยนแปลง ต้องเรียก API เพื่ออัพเดทข้อมูล
              const params = {
                intUserTemplateID: this.intUserTemplateID,
                intMemberID: this.intmemberID,
                // แปลง control field ให้ส่งไปแค่ ID
                UserInfoData: this.FilteridControln(this.NewlistUserinfo),
                // UserInfoData: this.NewlistUserinfo,
              };
              // console.log(params);
              const res = await axios.put(
                urlapi +
                  apiuserinfo.UpdateUserInfo +
                  this.intmemberID +
                  "?intInfoGroupID=" +
                  this.intGroupID +
                  "&intUserTypeID=" +
                  this.intUserTemplateID +
                  "&intUserGroupID=" +
                  this.intUsergrouptypeID,
                params
              );
              // console.log(
              //   urlapi +
              //     apiuserinfo.UpdateUserInfo +
              //     this.intmemberID +
              //     "?intInfoGroupID=" +
              //     this.intGroupID +
              //     "&intUserTypeID=" +
              //     this.intUserTemplateID +
              //     "&intUserGroupID=" +
              //     this.intUsergrouptypeID
              // );
              // console.log(res, params);
              if (res.data.edited == true) {
                this.strSMSGsuccess = "27";
                this.blnAltStatus = true;
                setTimeout(() => {
                  this.blnAltStatus = false;
                }, 2000);
                // await this.processLoadingData();
                this.blnLoading = true;
                await this.fetchUserfields(this.intindexOfGroupID);
                await this.fetchUserInfo(this.intGroupID);
                await this.searchUserInfo(this.studentID);
                await this.fixCountry();
                await this.UpdateProfileImage();
                setTimeout(() => {
                  this.blnaddData = false;
                  this.blnLoading = false;
                }, 1500);
                // console.log(this.keywordFromUpdate);
                // this.$emit("keywordFromUpdate", this.keywordFromUpdate);
                // this.$emit("EditSucess", true);
                // this.closeForm();
              } else if (res.data.edited == false) {
                this.strSMSGunsucess = "174";
                this.blnAltunsucess = true;
                setTimeout(() => {
                  this.blnaddData = false;
                  this.blnAltunsucess = false;
                }, 2000);
                // console.log(this.listMenu);
              }
            } else if (this.Action == "Insert" && this.newaction == "Insert") {
              // if (this.listMemberData.strCheckMemberStatus == "0") {
              //   this.intmemberID = 0;
              // } else {
              //   this.intmemberID = parseInt(
              //     this.listMemberData.strStudentData.strMemberID
              //   );
              // }
              const params = {
                intUserTemplateID: this.intUserTemplateID,
                intMemberID: this.intmemberID,
                // แปลง control field ให้ส่งไปแค่ ID
                UserInfoData: this.FilteridControln(this.NewlistUserinfo),
                // UserInfoData: this.NewlistUserinfo,
              };
              // console.log(params);
              // console.log(this.listMemberData);
              const res = await axios.post(
                urlapi +
                  apiuserinfo.InsertUserInfo +
                  "?strChecMemberStatus=" +
                  this.listMemberData.strCheckMemberStatus +
                  "&intUserTypeID=" +
                  this.intUserType +
                  "&intInfoGroupID=" +
                  this.intGroupID +
                  "&intUserGroupID=" +
                  this.intUsergrouptypeID,
                params
              );
              // console.log(
              //   urlapi +
              //     apiuserinfo.InsertUserInfo +
              //     "?strChecMemberStatus=" +
              //     this.listMemberData.strCheckMemberStatus +
              //     "&intUserTypeID=" +
              //     this.intUserType +
              //     "&intInfoGroupID=" +
              //     this.intGroupID +
              //     "&intUserGroupID=" +
              //     this.intUsergrouptypeID
              // );
              // console.log(res, params);
              if (res.data.inserted == true) {
                this.$emit("action", "Update_special");
                this.intmemberID = res.data.memberID;
                this.strSMSGsuccess = "26";
                this.blnAltStatus = true;
                setTimeout(() => {
                  this.blnAltStatus = false;
                }, 2000);

                // this.intGroupID = this.intGroupID + 1;
                this.intindexOfGroupID = this.intindexOfGroupID + 1;
                this.listMenu.forEach((item, index) => {
                  if (index === this.intindexOfGroupID) {
                    item.blnInsert = true;
                    this.intGroupID = item.intGroupID;
                  }
                });
                // เก็บค่าเพื่อไปค้นหาจากเมนูสุดท้าย
                this.NewlistUserinfo.forEach((ele) => {
                  if (ele.intUserFieldID == 1 || ele.intUserFieldID == 205) {
                    this.keywordFromUpdate = ele.data;
                  }
                });

                this.blnLoading = true;
                this.selectMenu(this.intGroupID, this.intindexOfGroupID);
                await this.fetchUserfields(this.intindexOfGroupID);
                await this.fetchUserInfo(this.intGroupID);
                await this.fixCountry();
                await this.UpdateProfileImage();
                setTimeout(() => {
                  this.blnaddData = false;
                  this.blnLoading = false;
                }, 1500);
                // console.log(this.listMenu);
              } else {
                this.blnAltStatusunsucess = true;
              }
            } else if (
              (this.Action == "Update" || this.Action == "Update_special") &&
              this.newaction == "Insert"
            ) {
              // ข้อมูลมีการเปลี่ยนแปลง ต้องเรียก API เพื่ออัพเดทข้อมูล
              const params = {
                intUserTemplateID: this.intUserTemplateID,
                intMemberID: this.intmemberID,
                // แปลง control field ให้ส่งไปแค่ ID
                UserInfoData: this.FilteridControln(this.NewlistUserinfo),
                // UserInfoData: this.NewlistUserinfo,
              };
              // console.log(params);
              const res = await axios.put(
                urlapi +
                  apiuserinfo.UpdateUserInfo +
                  this.intmemberID +
                  "?intInfoGroupID=" +
                  this.intGroupID,
                params
              );
              // console.log(
              //   urlapi +
              //     apiuserinfo.UpdateUserInfo +
              //     this.intmemberID +
              //     "?intInfoGroupID=" +
              //     this.intGroupID
              // );
              // console.log(res, params);
              if (res.data.edited == true) {
                this.strSMSGsuccess = "26";
                this.blnAltStatus = true;
                setTimeout(() => {
                  this.blnAltStatus = false;
                }, 2000);

                // await this.processLoadingData();
                this.blnLoading = true;
                // this.intGroupID = this.intGroupID + 1;
                this.intindexOfGroupID = this.intindexOfGroupID + 1;
                this.listMenu.forEach((item, index) => {
                  if (index === this.intindexOfGroupID) {
                    item.blnInsert = true;
                    this.intGroupID = item.intGroupID;
                  }
                });
                // console.log(
                //   this.listMenu.length,
                //   this.intindexOfGroupID + 1,
                //   this.keywordFromUpdate
                // );
                if (this.listMenu.length < this.intindexOfGroupID + 1) {
                  this.NewlistUserinfo.forEach((ele) => {
                    if (ele.intUserFieldID == 1 || ele.intUserFieldID == 205) {
                      this.keywordFromUpdate = ele.data;
                    }
                  });
                  // this.keywordFromUpdate =params.UserInfoData
                  // บันทึกหน้าสุดท้ายเสร็จให้กลับไปหน้าค้นหา
                  this.$emit("keywordFromUpdate", this.keywordFromUpdate);
                  this.$emit("EditSucess", true);
                  // เพื่อไม่ให้แสดงหน้าข้อมูลบัณฑิต (intGroupID = 6)
                  // this.intGroupID = oldintGroupID;
                  // this.intindexOfGroupID = oldintindexOfGroupID;
                  // this.listMenu.forEach((item) => {
                  //   if (item.intGroupID === this.intGroupID) {
                  //     item.blnInsert = true;
                  //   }
                  // });
                }

                await this.fetchUserfields(this.intindexOfGroupID);
                await this.fetchUserInfo(this.intGroupID);
                await this.searchUserInfo(this.studentID);
                await this.fixCountry();
                setTimeout(() => {
                  this.blnaddData = false;
                  this.blnLoading = false;
                }, 1500);

                // console.log(this.keywordFromUpdate);
                // this.$emit("keywordFromUpdate", this.keywordFromUpdate);
                // this.$emit("EditSucess", true);
                // this.closeForm();
              } else if (res.data.edited == false) {
                this.strSMSGunsucess = "174";
                this.blnAltunsucess = true;
                setTimeout(() => {
                  this.blnaddData = false;
                  this.blnAltunsucess = false;
                }, 5000);
              }
            }

            this.blnCheckData = false;
          } catch (error) {
            console.log(error);
            this.blnCheckData = false;
            this.strSMSGunsucess = "174";
            this.blnAltunsucess = true;
            setTimeout(() => {
              this.blnaddData = false;
              this.blnAltunsucess = false;
              // window.location.reload();
            }, 5000);
          }
        } else if (this.intdataChange == 0) {
          // ข้อมูลไม่มีการเปลี่ยนแปลง ไม่ต้องเรียก API
          // console.log("data not change");
          // this.$emit("keywordFromUpdate", this.keywordFromUpdate);
          // this.$emit("EditSucess", true);
          // this.closeForm();
          this.blnLoading = true;

          await this.fetchUserfields(this.intindexOfGroupID);
          await this.fetchUserInfo(this.intGroupID);
          await this.fixCountry();
          setTimeout(() => {
            this.blnaddData = false;
            this.blnLoading = false;
            this.blnCheckData = false;
          }, 1500);
        }
      } else {
        this.blnaddData = false;
      }
    },
    // filter ไอดีของ control field
    // แล้ว convert เป็น string
    FilteridControln(data) {
      // console.log(data);
      this.dummy = [];
      this.dummy = Array.from(data);
      this.dummy.forEach((ele) => {
        if (ele.data.id != undefined) {
          let id = ele.data.id;
          ele.data = id.toString();
        }
        if (ele.data.strID != undefined) {
          let id = ele.data.strID;
          ele.data = id.toString();
          // console.log(ele.data);
        }
        // console.log(ele.data);
      });
      // console.log(this.dummy);
      return this.dummy;
    },
    // ปิดฟอร์ม
    async closeForm() {
      //modify new model to compare with dummylist
      this.defaultdata = [];
      this.userInfo.forEach((ele) => {
        if (typeof ele.data === "object") {
          // console.log(ele);
          if (ele.intUserFieldID == 40) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[0].toString(),
            });
          } else if (ele.intUserFieldID == 41) {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data.id[1].toString(),
            });
          } else {
            this.defaultdata.push({
              intUserFieldID: ele.intUserFieldID,
              intUserInfoID: ele.intUserInfoID,
              data: ele.data?.id.toString(),
            });
          }
        } else {
          // console.log(ele)
          this.defaultdata.push({
            intUserFieldID: ele.intUserFieldID,
            intUserInfoID: ele.intUserInfoID,
            data: ele.data,
          });
        }
      });
      //select data from default data
      let defaultdata = Enumerable.from(this.DummyListUserinfoID)
        .select((s) => s.data)
        .toArray();
      //select data from updated data
      let newdata = Enumerable.from(this.defaultdata)
        .select((s) => s.data)
        .toArray();
      let result = Enumerable.from(defaultdata).except(newdata).toArray();
      // console.log(result);
      //gruop change check
      if (
        (result != "" && this.dummy == "") ||
        (result != "" && this.dummy != "")
      ) {
        this.blnAltcancel = true;
      } else {
        // await this.$refs.UserInfoForm.oncancel();
        // console.log(this.NewlistUserinfo, this.newaction);
        // console.log(this.DummyListUserinfoID);
        let intcheck = 0;
        if (this.newaction == "Update") {
          this.DummyListUserinfoID.forEach((element) => {
            this.NewlistUserinfo.forEach((ele) => {
              if (element.intUserFieldID == ele.intUserFieldID) {
                if (JSON.stringify(element.data) != JSON.stringify(ele.data)) {
                  intcheck++;
                }
              }
            });
          });
        } else {
          this.NewlistUserinfo?.forEach((ele) => {
            if (ele.data != "" && ele.data != "0") {
              intcheck++;
            }
          });
        }
        if (this.intGroupID != 9) {
          if (intcheck == 0) {
            this.$emit("closeForm", false);
          } else {
            this.blnAltcancel = true;
          }
        }

        // ---------------------------
        // ตรวจสอบข้อมูลเปลี่ยนแปลงในเมนูข้อมูลตำแหน่ง
        if (this.DummyListUserinfoID.length != 0 && this.intGroupID == 9) {
          let count = 0;
          // มีข้อมูลเดิมอยู่แล้ว
          // console.log(this.DummyListUserinfoID);
          // console.log(this.listUpdateData);
          this.DummyListUserinfoID.forEach((element) => {
            this.listUpdateData.forEach((ele) => {
              if (
                element.intUserFieldID == ele.userInfo.intUserFieldID &&
                element.intGroupLink == ele.userInfo.intGroupLink
              ) {
                // console.log(element, ele.userInfo);
                if (ele.userInfo.data?.id == undefined) {
                  if (element.data != ele.userInfo.data) {
                    // console.log(
                    //   element.intUserFieldID,
                    //   element.data,
                    //   ele.userInfo.data
                    // );
                    count++;
                  }
                } else {
                  if (element.data != ele.userInfo.data.id) {
                    // console.log(
                    //   element.intUserFieldID,
                    //   element.data,
                    //   ele.userInfo.data
                    // );
                    count++;
                  }
                }
              }
            });
          });
          // ตรวจสอบค่าที่ไม่มีจาก api (เพิ่มเติมจาก api)
          const result = this.listUpdateData.filter(
            (item2) =>
              !this.DummyListUserinfoID.some(
                (item1) =>
                  item1.intUserFieldID === item2.userInfo.intUserFieldID
              )
          );

          // filter ค่า null ออก
          const filteredData = result.filter(
            (item) => item.userInfo.data !== null && item.userInfo.data != "0"
          );
          if (filteredData.length > 0) {
            count++;
          }

          if (count == 0) {
            this.$emit("closeForm", false);
          } else {
            this.blnAltcancel = true;
          }
        }
      }
    },
    // ค้นหาข้อมูลผู้ใช้
    async searchUserInfo(keyword) {
      // console.log(keyword);
      // console.log(this.intUsergrouptypeID);
      try {
        var res = await axios.post(
          urlapi +
            apiuserinfo.GetUserInfoListByKeyword +
            keyword +
            "/" +
            this.intUsergrouptypeID,
          this.listUserfieldIDcard
        );
        // console.log(res);
        res?.data?.data?.[0].listUserInfoData.forEach((element) => {
          if (element.intMemberID == this.intmemberID) {
            // console.log(element);
            this.listUserInfoOnEdit = [];
            this.listUserInfoOnEdit.push(element);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    // PopUp ตรวจสอบว่าผู้ใช้นี้มีข้อมูลอยู่แล้วหรือไม่
    checkMemberData(memberData) {
      // console.log(memberData);
      this.listMemberData = memberData;
      if (
        memberData.strCheckMemberStatus == "1" ||
        memberData.strCheckMemberStatus == "2"
      ) {
        this.memberStatus = memberData.strCheckMemberStatus;
        this.intmemberID = parseInt(memberData.strStudentData.strMemberID);
        this.blnPopUpCheckmember = true;
        if (this.$i18n.locale == "th") {
          this.strPopUpCheckmember =
            "คุณยืนยันที่จะแก้ไขข้อมูลผู้ใช้ของ " +
            memberData.strStudentData.strCode +
            " " +
            memberData.strStudentData.strFirstNameTH +
            " " +
            memberData.strStudentData.strMidNameTH +
            " " +
            memberData.strStudentData.strLastNameTH +
            " ใช่หรือไม่";
        } else {
          this.strPopUpCheckmember =
            "Do you want to edit " +
            memberData.strStudentData.strCode +
            " " +
            memberData.strStudentData.strFirstNameEN +
            " " +
            memberData.strStudentData.strMidNameEN +
            " " +
            memberData.strStudentData.strLastNameEN +
            " user information ?";
        }
      } else {
        this.intmemberID = 0;
      }
    },
    // popUp ยืนยันการแก้ไขข้อมูลคนที่มีข้อมูลอยู่แล้ว
    confirmCheckmember(state) {
      this.blnPopUpCheckmember = false;
      if (state == true) {
        if (this.memberStatus == "1") {
          // intUserFieldID ตาม userGroup
          let firstNameTH;
          let firstNameEN;
          let lastNameTH;
          let lastNameEN;

          let stdentCode;
          switch (this.intUserTemplateID) {
            case 1:
              stdentCode = 1;
              firstNameTH = 4;
              lastNameTH = 6;
              firstNameEN = 8;
              lastNameEN = 10;
              break;
            case 2:
              stdentCode = 145;
              firstNameTH = 148;
              lastNameTH = 150;
              firstNameEN = 152;
              lastNameEN = 154;
              break;
            default:
              break;
          }
          let makeInfo = [
            {
              intUserFieldID: stdentCode,
              intUserInfoID: null,
              data: this.listMemberData.strStudentData.strCode,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: firstNameTH,
              intUserInfoID: null,
              data: this.listMemberData.strStudentData.strFirseNameTH,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: lastNameTH,
              intUserInfoID: null,
              data: this.listMemberData.strStudentData.strLastNameTH,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: firstNameEN,
              intUserInfoID: null,
              data: this.listMemberData.strStudentData.strFirstNameEN,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
            {
              intUserFieldID: lastNameEN,
              intUserInfoID: null,
              data: this.listMemberData.strStudentData.strLastNameEN,
              strDataImage: "",
              intOrder: 0,
              intDataGroupType: 0,
            },
          ];
          // console.log(this.listMemberData);
          // console.log(makeInfo);
          this.userInfo = makeInfo;
          // console.log(this.userInfo);
        } else if (this.memberStatus == "2") {
          this.$emit("gotoUpdate", {
            intMemberID: parseInt(
              this.listMemberData.strStudentData.strMemberID
            ),
            typeID: this.intUserTemplateID,
            intUserTemplateID: this.intUserTemplateID,
            strStudentCode: this.listMemberData.strStudentData.strCode,
          });
        } else {
          this.fetchUserInfo(this.intGroupID);
        }
        // เปิด disable form
        this.$refs.UserInfoForm.blnDisableAll = false;
      } else if (state == false) {
        this.$refs.UserInfoForm.blncheckMemberComplete = false;
      }
    },
    // ตรวจสอบว่าผู้ใช้นี้มีข้อมูลอยู่แล้วหรือไม่
    async checkMember(keyword) {
      let intUserFieldID = 1;
      if (this.intUserTemplateID == 1) {
        intUserFieldID = 1;
        if (keyword.length < 8) {
          return;
        }
      } else if (this.intUserTemplateID == 2) {
        // intUserFieldID = 145;
        intUserFieldID = 1;
        if (keyword.length < 8) {
          return;
        }
      } else {
        intUserFieldID = 205;
        if (keyword.length < 10) {
          return;
        }
      }

      if (this.userInfo?.length != 0) {
        // console.log(this.userInfo);
        let intIDRepeat = 0;
        this.userInfo.forEach((e) => {
          if (
            (e.intUserFieldID == 1 || e.intUserFieldID == 205) &&
            e.data == keyword
          ) {
            intIDRepeat++;
          }
        });
        // clear input search
        this.$refs?.inputSearch.clean();

        if (intIDRepeat != 0) {
          return;
        }
      }
      try {
        var res = await axios.get(
          urlapi + apiuserinfo.GetCheckUserInfo + keyword
        );
        // console.log(res);
        if (res.data.data.strCheckMemberStatus == "0") {
          // ไม่มี Member และ ไม่มี userInfo
          // this.blnDisableAll = false;
          this.$refs.UserInfoForm.blnDisableAll = false;

          localStorage.setItem("userinfoInsert", true);
          this.checkMemberData(res.data.data);
          let makeInfo = [];
          if (intUserFieldID == 1) {
            makeInfo = [
              {
                intUserFieldID: intUserFieldID,
                intUserInfoID: 0,
                data: keyword,
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 2,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 4,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 5,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 6,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 8,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 9,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 10,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 11,
                intUserInfoID: 0,
                data:
                  new Date().getDate().toString() +
                  "/" +
                  new Date().getMonth().toString() +
                  "/" +
                  (new Date().getFullYear() + 526).toString(),
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 12,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 13,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 14,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 15,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 16,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 17,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 18,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 19,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
            ];
            if (this.$refs?.UserInfoForm?.$refs["11"] != undefined) {
              this.$refs?.UserInfoForm?.$refs["11"]?.[0].removeData();
            }
            if (this.$refs?.UserInfoForm?.$refs["2"] != undefined) {
              this.$refs?.UserInfoForm?.$refs["2"]?.[0].onclear();
            }
            if (this.$refs?.UserInfoForm?.$refs["19"] != undefined) {
              this.$refs?.UserInfoForm?.$refs[
                "19"
              ]?.[0]?.$refs?.inputfiles?.removeImage();
            }
          } else if (intUserFieldID == 205) {
            makeInfo = [
              {
                intUserFieldID: intUserFieldID,
                intUserInfoID: 0,
                data: keyword,
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 4,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 5,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 6,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 8,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 9,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 10,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 214,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 215,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
              {
                intUserFieldID: 216,
                intUserInfoID: 0,
                data: "",
                strDataImage: "",
                intOrder: 0,
                intDataGroupType: 0,
              },
            ];
            if (this.$refs?.UserInfoForm?.$refs["216"] != undefined) {
              this.$refs?.UserInfoForm?.$refs[
                "216"
              ]?.[0]?.$refs?.inputfiles?.removeImage();
            }
          }

          this.userInfo = makeInfo;
          this.keywordFromUpdate = keyword;
        } else {
          this.checkMemberData(res.data.data);
        }

        // clear input search
        this.$refs?.inputSearch.clean();
      } catch (error) {
        console.log(error);
      }
    },
    // API ตรวจสอบข้อมูลว่าผู้ใช้นี้มีข้อมูลอยู่แล้วหรือไม่
    async fetchGetCheckUserinfoRequire(intMemberID) {
      try {
        const res = await axios.put(
          urlapi +
            apiuserinfo.GetCheckUserinfoRequire +
            "?intMemberID=" +
            intMemberID
        );
        console.log(res);
      } catch (error) {
        this.strSMSGunsucess = "174";
        this.blnAltunsucess = true;
        setTimeout(() => {
          this.blnaddData = false;
          this.blnAltunsucess = false;
        }, 5000);
        console.log(error);
      }
    },
    // ตรวจสอบปิดปุ่มตกลง (Disable)
    disbleSubmit(event) {
      // console.log(event);
      this.listUpdateData = [];
      let listdata = event;
      let count = 0;
      this.listUpdateData = event;
      this.listcheckDisable.forEach((element) => {
        listdata.forEach((ele) => {
          if (element.strID == ele.userInfo.intUserFieldID) {
            // ถ้าเป็น reques ต้องใส่ข้อมูล
            if (
              element.intNotnull == 1 &&
              (ele.userInfo.data == "" ||
                ele.userInfo.data == undefined ||
                ele.userInfo.data == null ||
                ele.userInfo.data == "{}")
            ) {
              count++;
            }
          }
        });
      });
      if (count != 0) {
        // disable
        this.blnCheckData = true;
      } else {
        // enable
        this.blnCheckData = false;
      }
    },
    // รับค่าUserType จากการเลือกตำแหน่งในฟอร์ม (UserType = 3)
    changUserType(intUserType) {
      // console.log(intUserType);
      this.intUserType = intUserType;
    },
    async UpdateProfileImage() {
      // อัพเดท userinfo ใน cookie เพื่ออัพเดทในโมดูลอื่นๆ
      // console.log(this.strURLUpdateProfileIMG);
      if (
        this.strURLUpdateProfileIMG != "" &&
        this.strURLUpdateProfileIMG != null &&
        this.strURLUpdateProfileIMG != undefined &&
        this.strURLUpdateProfileIMG != "https://cesfile.wu.ac.th/files"
      ) {
        if (AdsInventories.userinfo.strUserCode == this.strUserCode) {
          AdsInventories.userinfo.urlImageProfile = this.strURLUpdateProfileIMG;
          let token = JSON.stringify(AdsInventories.userinfo);
          VueCookies.set(
            "UserInfo",
            token,
            "1d",
            "/",
            cookiesPath,
            true,
            "None"
          );
          // เปลี่ยนรูปโปรไฟล์ของ user ที่ login อยู่
          // document.getElementById("ImageProfile").src =
          // this.strURLUpdateProfileIMG;
        }
      } else {
        AdsInventories.userinfo.urlImageProfile = "";
        let token = JSON.stringify(AdsInventories.userinfo);
        VueCookies.set("UserInfo", token, "1d", "/", cookiesPath, true, "None");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  &:hover {
    cursor: pointer;
  }
}
</style>
