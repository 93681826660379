<template>
  <div
    v-if="
      listKeyword != undefined
        ? listKeyword.length != 0
        : listKeyword != undefined
    "
    class="w-100"
  >
    <template v-for="(item, index) in sortfilter" :key="index">
      <div
        v-if="item.intUserFieldID == 8"
        class="d-flex flex-column align-items-start justify-content-start mb-4"
      >
        <Checkbox
          :checkboxLabel="$t('89')"
          @select="selectStatusgroup($event, 1, index)"
          :ref="'checkboxGroup1'"
        />
        <template v-for="(child, childIndex) in findActive" :key="childIndex">
          <div class="ms-4">
            <Checkbox
              :checkboxLabel="child[filterLang(child)]"
              @select="selectStatus($event, child, index, item.intUserFieldID)"
              :ref="'checkbox' + child.id + index"
            />
          </div>
        </template>
        <Checkbox
          :checkboxLabel="$t('90')"
          @select="selectStatusgroup($event, 0, index)"
          :ref="'checkboxGroup2'"
        />
        <template v-for="(child, childIndex) in findInActive" :key="childIndex">
          <div class="ms-4">
            <Checkbox
              :checkboxLabel="child[filterLang(child)]"
              @select="selectStatus($event, child, index, item.intUserFieldID)"
              :ref="'checkbox' + child.id + index"
            />
          </div>
        </template>
      </div>

      <div
        v-if="item.intUserFieldID != 8 && item.intUserFieldID != 41"
        class="d-flex flex-column align-items-start justify-content-start mb-4"
      >
        <Label :label="item[filterLang(item)]" class="mb-2 fnt-medium" />
        <template
          v-for="(child, childIndex) in item.listFilterGroupData"
          :key="childIndex"
        >
          <Checkbox
            :checkboxLabel="child[filterLang(child)]"
            :checked="checked"
            :strId="child.id == undefined
                ? 'checkbox' + child.strID
                : 'checkbox' + child.id"
            @select="selectStatus($event, child, index, item.intUserFieldID)"
            :ref="
              child.id == undefined
                ? 'checkbox' + child.strID
                : 'checkbox' + child.id
            "
          />

          <template
            v-for="(child, childIndex) in child.listChild"
            :key="childIndex"
          >
            <div class="ms-4">
              <Checkbox
                :checkboxLabel="child[filterLang(child)]"
                :checked="checked"
                :strId="child.id == undefined
                    ? 'checkbox' + child.strID
                    : 'checkbox' + child.id"
                @select="
                  selectStatus($event, child, index, item.intUserFieldID)
                "
                :ref="
                  child.id == undefined
                    ? 'checkbox' + child.strID
                    : 'checkbox' + child.id
                "
              />
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Label from "@/components/label/label.vue";
import Checkbox from "@/components/input/checkbox.vue";

import customfilter from "@/typescript/filter";
import Enumerable from "linq";

export default {
  data() {
    return {
      checked: true,
      blnActive: false,
      blnInactive: false,
      listChildFilter: [],
      listFilterKeyword: [],
    };
  },
  props: {
    listKeyword: [],
  },

  computed: {
    sortfilter() {
      return this.processSortFilter();
    },
    findActive() {
      var Active = [];

      this.listKeyword.forEach((element) => {
        if (element.intUserFieldID == 8) {
          Active = element.listGroupdata.filter((ele) => {
            if (ele.strActiveFlag == 1) {
              return true;
            }
          });
        }
      });
      return Active;
    },
    findInActive() {
      var Active = [];
      this.listKeyword.forEach((element) => {
        if (element.intUserFieldID == 8) {
          Active = element.listGroupdata.filter((ele) => {
            if (ele.strActiveFlag == 0) {
              return true;
            }
          });
        }
      });
      return Active;
    },
  },
  emits: ["selectfilter"],
  components: {
    Label,
    Checkbox,
    // Radio,
  },
  mounted() {
    this.processSortFilter();

    // this.getUserfieldTypeControlTree(0, "ads_academicorg);
    // this.getUserfieldTypeControlTree(1, "ads_agency");
  },

  //เรียกข้อมูลการตั้งค่าภาษาตอนเปิดหน้าเว็บ
  // beforeMount() {
  //   //เรียกข้อมูลจาก local
  //   this.locales = localStorage.getItem("language");
  // },
  methods: {
    processSortFilter() {
      let academic = Enumerable.from(this.listKeyword)
        .where((innerlist) => innerlist.intUserFieldID == 40)
        .selectMany((innerlist) => innerlist.listFilterGroupData)
        .toArray();

      let program = Enumerable.from(this.listKeyword)
        .where((innerlist) => innerlist.intUserFieldID == 41)
        .selectMany((innerlist) => innerlist.listFilterGroupData)
        .groupBy(
          (groupData) => groupData.strNameEN,
          (groupData) => ({
            strNameTH: groupData.strNameTH,
            strID: groupData.strID,
            strParentID: groupData.strParentID,
          })
        )
        .select((group) => {
          // console.log(group);
          return {
            strNameEN: group.key(),
            strNameTH: group
              .select((item) => item.strNameTH)
              .distinct()
              .toArray()
              .join(","),
            strID: group
              .select((item) => item.strID)
              .distinct()
              .toArray()
              .join(","),
            strParentID: group
              .select((item) => item.strParentID)
              .distinct()
              .toArray()
              .join(","),
            // Add more properties here if needed
          };
        })
        .toArray();

  

      academic.forEach((item) => {
        item.listChild = Enumerable.from(program)
          .where((innerItem) => innerItem.strParentID === item.strID.toString())
          .orderBy((item) => {
            if (this.$i18n.locale == "en") {
              return item.strNameEN;
            } else {
              return item.strNameTH;
            }
          })
          .toArray();
      });

      // console.log(academic);
      // console.log(program);
      let sortedfilter = Enumerable.from(this.listKeyword)
        .where((innerlist) => innerlist.listFilterGroupData)
        .select((innerlist) => {
          innerlist.listFilterGroupData = Enumerable.from(
            innerlist.listFilterGroupData
          )
            .orderBy((item) => {
              if (this.$i18n.locale == "en") {
                return item.strNameEN;
              } else {
                return item.strNameTH;
              }
            })
            .toArray();

          return innerlist;
        })
        .toArray();

      // console.log(sortedfilter);

      return sortedfilter;
    },
    filterLang(items) {
      // console.log(this.defaultLang);

      return customfilter.filterLang(items, this.$i18n.locale);
    },

    async selectStatus(state, filter, index, intUserFieldID) {
      // console.log(filter);

      let id = Enumerable.from([filter])

        .select((s) => s.strID)
        .firstOrDefault();
      let parentid = Enumerable.from([filter])

        .select((s) => s.strParentID)
        .firstOrDefault();

      if (state == true) {
        if (parentid !== "0" && intUserFieldID == 40) {
          intUserFieldID = 41;
        }
        this.listFilterKeyword.push({ userfieldID: intUserFieldID, strID: id });
        // กรณี array 1 ตัว
        if (filter.listChild != undefined) {
          for (let i = 0; i < filter.listChild.length; i++) {
            this.$refs["checkbox" + filter.listChild[i].strID][0].check = true;
          }
        }
      } else if (state == false) {
        this.listFilterKeyword = this.listFilterKeyword.filter((item) => {
          // console.log(id);
          return item.strID !== id;
        });
        // กรณี array 1 ตัว
        if (filter.listChild != undefined) {
          for (let i = 0; i < filter.listChild.length; i++) {
            this.$refs["checkbox" + filter.listChild[i].strID][0].check = false;
          }
        }
      }
      this.$emit("selectfilter", {
        listFilterKeyword: this.listFilterKeyword,
        filter: filter,
      });
    },

    selectStatusgroup(state, id, index) {
      if (state == true) {
        if (id == 0) {
          this.findInActive.forEach((ele) => {
            if (typeof this.listFilterKeyword[index] == "undefined") {
              this.listFilterKeyword.push([
                {
                  intUserfieldID: 0,
                  strFilter: ele,
                },
              ]);
              this.$refs["checkbox" + ele.id + index][0].check = true;
            } else {
              var blnHave = false;
              this.listFilterKeyword[index].forEach((child) => {
                if (child.strFilter.id == ele.id) {
                  blnHave = true;
                }
              });

              if (blnHave == false) {
                this.listFilterKeyword[index].push({
                  intUserfieldID: 0,
                  strFilter: ele,
                });
                this.$refs["checkbox" + ele.id + index][0].check = true;
              }
            }
          });
        }
        if (id == 1) {
          this.findActive.forEach((ele) => {
            if (typeof this.listFilterKeyword[index] == "undefined") {
              this.listFilterKeyword.push([
                {
                  intUserfieldID: 0,
                  strFilter: ele,
                },
              ]);
              this.$refs["checkbox" + ele.id + index][0].check = true;
            } else {
              var blnHave = false;
              this.listFilterKeyword[index].forEach((child) => {
                if (child.strFilter.id == ele.id) {
                  blnHave = true;
                }
              });

              if (blnHave == false) {
                this.listFilterKeyword[index].push({
                  intUserfieldID: 0,
                  strFilter: ele,
                });

                this.$refs["checkbox" + ele.id + index][0].check = true;
              }
            }
          });
        }
      } else if (state == false) {
        if (id == 0) {
          this.findInActive.forEach((child) => {
            this.listFilterKeyword[index] = this.listFilterKeyword[
              index
            ].filter((ele) => {
              if (ele.strFilter.id == child.id) {
                this.$refs["checkbox" + child.id + index][0].check = false;
              }
              return ele.strFilter.id !== child.id;
            });
          });
        }
        if (id == 1) {
          this.findActive.forEach((child) => {
            this.listFilterKeyword[index] = this.listFilterKeyword[
              index
            ].filter((ele) => {
              if (ele.strFilter.id == child.id) {
                this.$refs["checkbox" + child.id + index][0].check = false;
              }
              return ele.strFilter.id !== child.id;
            });
          });
        }
      }

      this.$emit("selectfilter", this.listFilterKeyword);
    },
  },
};
</script>

<style lang="scss" scoped></style>
